import { useCallback, useMemo, useState } from "react";
import  { languages } from "../../hooks/useLocal";
import {  doc, getDoc, onSnapshot } from "firebase/firestore";
import { firebaseDb } from "../../firebaseConfig";

import yerevanAlekseevkaAvtobus from "../../images/routes/yerevan-alekseevka-avtobus.jpg";
import yerevanKurskAvtobus from "../../images/routes/yerevan-kursk-avtobus.jpg";
import yerevanBelgorodAvtobus from "../../images/routes/yerevan-belgorod-avtobus.jpg";
import rostov from "../../images/routes/yerevan-rostov-avtobus.jpg";
import stariy_oskol from "../../images/routes/yerevan-stariy_oskol-avtobus.jpg";
import vladikavkaz from "../../images/routes/yerevan-vladikavkaz-avtobus.jpg";
import valuyki from "../../images/routes/yerevan-valuyki-avtobus.jpg";
import voronej from "../../images/routes/yerevan-voronej-avtobus.jpg";
import miniralnie_vodi from "../../images/routes/yerevan-miniralnie_vodi-avtobus.jpg";
import { IRoute } from "../../interfaces";
import { generateUniqueToken, getTimeNowForMessage } from "../../utils";
import { useFirebase } from "../../hooks/useFirebase";
import { TChat, TMessage } from "../../components/Callback/types";
import { TelegramClient } from "messaging-api-telegram";
import { translates } from "../../translates";

const initialRoutes: IRoute[] = [
  {
    name: "yerevan-alekseevka-avtobus",
    from: "yerevan",
    to: "alekseevka",
    amd: 39000,
    rub: 7200,
    distance: "1694.2 ",
    time: "23",
    img: yerevanAlekseevkaAvtobus,
    keys: "",
  },
  // {
  //   name: "yerevan-kursk-avtobus",
  //   from: "yerevan",
  //   to: "kursk",
  //   amd: 32000,
  //   rub: 5000,
  //   distance: "1947.2",
  //   time: "26",
  //   img: yerevanKurskAvtobus,
  //   keys: "",
  // },
  {
    name: "yerevan-belgorod-avtobus",
    from: "yerevan",
    to: "belgorod",
    amd: 37000,
    rub: 6500,
    distance: "1859.5",
    time: "25",
    img: yerevanBelgorodAvtobus,
    keys: "",
  },
  {
    name: "yerevan-rostov-avtobus",
    from: "yerevan",
    to: "rostov",
    amd: 41000,
    rub: 7400,
    distance: "1163.8",
    time: "17",
    img: rostov,
    keys: "",
  },
  {
    name: "yerevan-stariy_oskol-avtobus",
    from: "yerevan",
    to: "stary_oskol",
    amd: 33500,
    rub: 5400,
    distance: "1819.2",
    time: "24",
    img: stariy_oskol,
    keys: "",
  },
  {
    name: "yerevan-vladikavkaz-avtobus",
    from: "yerevan",
    to: "vladikavkaz",
    amd: 38500,
    rub: 6800,
    distance: "468.5",
    time: "9",
    img: vladikavkaz,
    keys: "",
  },
  {
    name: "yerevan-valuyki-avtobus",
    from: "yerevan",
    to: "valuyki",
    amd: 36000,
    rub: 6100,
    distance: "1724.9",
    time: "23",
    img: valuyki,
    keys: "",
  },
  {
    name: "yerevan-voronej-avtobus",
    from: "yerevan",
    to: "voronej",
    amd: 34000,
    rub: 5600,
    distance: "1723.3",
    time: "23",
    img: voronej,
    keys: "",
  },
  {
    name: "yerevan-miniralnie_vodi-avtobus",
    from: "yerevan",
    to: "miniralnie_vodi",
    amd: 40000,
    rub: 7300,
    distance: "682.9",
    time: "12",
    img: miniralnie_vodi,
    keys: "",
  },
];

const initialChat = {
  newMessages: [],
  messages: [],
};

export const useAppContext = () => {
  const [activeLang, setActiveLang] = useState(languages[0]);
  const changeLang = useCallback(
    () => setActiveLang(languages[activeLang.id === 0 ? 1 : 0]),
    [activeLang.id]
  );
  const [activeReservation, setActiveReservation] = useState<string | null>(
    null
  );
  const [routes, setRoutes] = useState<IRoute[]>([]);
  const [routesLoading, setRoutesLoading] = useState(true);
  const [isInitialized, setInitialized] = useState(false);

  const getPrices = useCallback(async () => {
    try {
      setRoutesLoading(true);
      const collectionRef = doc(firebaseDb, "/routes", "/prices");
      const docSnap = await getDoc(collectionRef);
      const data: any = docSnap.data();
      const newRoutes = initialRoutes.map((i) => ({
        ...i,
        amd: data[i.name][1],
        rub: data[i.name][0],
      }));
      setRoutes(newRoutes);
      setInitialized(true);
    } catch (error) {
      setRoutes(initialRoutes);
      console.error(error);
    } finally {
      setRoutesLoading(false);
    }
  }, []);
  const isInitLoading = useMemo(() => routesLoading, [routesLoading]);
  const findRoute = useCallback(
    (name: string) => routes.find((i) => i.name === name) || routes[0],
    [routes]
  );

  // ================ CHAT ================
  const { changeData, getData } = useFirebase();
  const [chat, setChat] = useState<TChat>(initialChat);

  const sendMessageFromOperator = useCallback(
    async (options: { initChat?: TChat; message: string; chatId: string }) => {
      const { initChat, message, chatId } = options;
      try {
        const activeChat = initChat || chat;
        const messageId = activeChat.messages.length;
        const time = getTimeNowForMessage();
        const messageValue: TMessage = {
          id: messageId,
          message,
          sender: 0,
          time,
        };
        await changeData("chat", chatId, {
          messages: [...activeChat.messages, messageValue],
          newMessages: [...activeChat.newMessages, messageValue.id],
        } as TChat);
      } catch (error) {
        console.error(error);
      }
    },
    [changeData, chat]
  );

  const client = new TelegramClient({
    accessToken: "7290784093:AAHh9bmVXbnD7oFbsF_4dOTSCeUmohJRMoA",
  });

  const showNewMessages = useCallback((count: number) => {
    const element = document.querySelector(".callback-new-messages");
    if (element) {
      element.classList.add("showNewMessages-visible");

      setTimeout(() => {
        element.classList.remove("showNewMessages-visible");
      }, 3000);
    }
  }, []);

  const sendMessageFromUser = useCallback(
    async (message: string) => {
      const activeToken = localStorage.getItem("rusarm-token");
      if (!activeToken) return;
      try {
        const messageId = chat.messages.length;
        const time = getTimeNowForMessage();
        const messageValue: TMessage = {
          id: messageId,
          message,
          sender: 1,
          time,
        };
        await changeData("chat", activeToken, {
          messages: [...chat.messages, messageValue],
          newMessages: [],
        } as TChat);

        const value = `Новое сообщение от (${activeToken}), сообщение = ${message}, для ответа перейдите rusarm-transits.am/admin/chat/${activeToken}`;
        await client.sendMessage("-4285204537", value);
      } catch (error) {
        console.error(error);
      }
    },
    [changeData, chat.messages, client]
  );

  const initializeChat = useCallback(async () => {
    try {
      const activeToken = localStorage.getItem("rusarm-token");
      if (activeToken) {
        const chat = await getData<TChat>("chat", activeToken);
        if (chat) setChat(chat);
        return;
      }
      const newToken = generateUniqueToken();
      localStorage.setItem("rusarm-token", newToken);

      await changeData("chat", newToken, {
        newMessages: [],
        messages: [],
      });
      const value = translates.find((i) => i.key === "help_first_message");
      setTimeout(() => {
        sendMessageFromOperator({
          initChat: initialChat,
          chatId: newToken,
          message:
            activeLang.value === "am"
              ? value?.label_am || "Ողջույն! Ինչպե՞ս կարող եմ օգնել ձեզ:"
              : value?.label_ru || "Привет! Как я могу помочь вам?",
        });
      }, 8000);
    } catch (error) {
      console.error(error);
    }
  }, [activeLang.value, changeData, getData, sendMessageFromOperator]);

  const followUserChat = useCallback(() => {
    const activeToken = localStorage.getItem("rusarm-token");

    if (!activeToken) return;
    const docRef = doc(firebaseDb, "chat", activeToken);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const newUserChat = doc.data() as TChat;
        if (newUserChat.newMessages.length) {
          showNewMessages(newUserChat.newMessages.length);
        }
        setChat(newUserChat);
      } else {
        console.log("Документ не существует!");
      }
    });
    return () => unsubscribe();
  }, [showNewMessages]);

  return {
    changeLang,
    activeLang,
    activeReservation,
    setActiveReservation,
    getPrices,
    routes,
    isInitLoading,
    findRoute,
    isInitialized,
    initializeChat,
    followUserChat,
    chat,
    sendMessageFromUser,
    sendMessageFromOperator,
    showNewMessages,
  };
};
