import { memo } from "react";
import styled from "styled-components";

import { FaListCheck } from "react-icons/fa6";
import { FaSuitcaseRolling, FaUserCheck } from "react-icons/fa";
import { BsFire } from "react-icons/bs";
import useLocal from "../../hooks/useLocal";
import { media } from "../../ui/config";

export const FaqInfo = memo(() => {
  const { t } = useLocal();
  return (
    <StyledFaqInfo>
      <StyledRow>
        {/* <img src={likes.src} alt="likes" /> */}
        <StyledTitle>{t("helpful_information")}</StyledTitle>
      </StyledRow>
      <InfoBlock>
        <InfoItem>
          <InfoItemLeft bg="rgb(255 243 224)">
            <BsFire size={50} color="rgb(244 125 2)" />
          </InfoItemLeft>
          <InfoItemRight>
            {t("info_1_1")}, <span>{t("info_1_2")}</span>, <span>WiFi</span>,{" "}
            {t("info_1_3")} <span>2-3 {t("info_1_4")}</span>
          </InfoItemRight>
        </InfoItem>
        <InfoItem>
          <InfoItemLeft bg="rgb(222 234 255)">
            <FaListCheck size={42} color="rgb(11 115 254)" />
          </InfoItemLeft>
          <InfoItemRight>
            <span>300+</span> {t("info_2_1")}, <span>1500+</span>{" "}
            {t("info_2_2")}
          </InfoItemRight>
        </InfoItem>
        <InfoItem>
          <InfoItemLeft bg="rgb(68 193 82 / 17%)">
            <FaUserCheck size={45} color="rgb(68 193 82)" />
          </InfoItemLeft>
          <InfoItemRight>
            {t("info_3_1")} <span>20+</span> {t("info_3_2")}
          </InfoItemRight>
        </InfoItem>
        <InfoItem mb={"0"}>
          <InfoItemLeft bg="rgb(231 56 74 / 16%)">
            <FaSuitcaseRolling size={45} color="rgb(248 83 100)" />
          </InfoItemLeft>
          <InfoItemRight>
            {t("info_4_1")} <span>10{t("kg")}.</span>
          </InfoItemRight>
        </InfoItem>
      </InfoBlock>
    </StyledFaqInfo>
  );
});

const StyledFaqInfo = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  background: white;
  min-height: 200px;
  margin: 20px 0;
`;
const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;
const StyledTitle = styled.span`
  font-size: 30px;
  font-weight: 900;
`;

const InfoItem = styled.div<{ mb?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  transition: all 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.01);
  }
`;

const InfoItemLeft = styled.div<{ bg: string }>`
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${(p) => p.bg};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InfoItemRight = styled.span`
  font-weight: 500;
  font-size: 20px;
  text-align: center;

  span {
    font-weight: 800;
  }
`;

FaqInfo.displayName = "FaqInfo";

const InfoBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  ${media.tabletL`
  grid-template-columns: repeat(2, 1fr);
  `}
  ${media.mobileL`
  grid-template-columns: repeat(1, 1fr);
  `}
`;
