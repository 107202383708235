import { memo, forwardRef } from "react";
import styled from "styled-components";
import { TChat } from "./types";
import { FcAssistant } from "react-icons/fc";
import useLocal from "../../hooks/useLocal";
import { HiUserCircle } from "react-icons/hi2";

export const MessagesList = memo(
  forwardRef<HTMLDivElement, { chat: TChat }>((props, ref) => {
    const { chat } = props;
    const { t } = useLocal();

    return (
      <StyledMessagesList ref={ref} id="messages-list">
        {chat.messages.map((message) => (
          <StyledMessage key={message.id} $isUser={message.sender !== 0}>
            {message.sender === 0 ? (
              <StyledWe>
                <FcAssistant size={30} />
              </StyledWe>
            ) : (
              <StyledClient>
                <HiUserCircle size={30} />
              </StyledClient>
            )}
            <StyledMessageContent $isUser={message.sender !== 0}>
              <div>
                <span>{message.time}</span>
                {chat.newMessages.find((id) => id === message.id) ? (
                  <span className="novoie">{t("new_message")}</span>
                ) : null}
              </div>
              <span>{message.message}</span>
            </StyledMessageContent>
          </StyledMessage>
        ))}
      </StyledMessagesList>
    );
  })
);
const StyledMessagesList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
`;
const StyledMessage = styled.div<{ $isUser: boolean }>`
  flex-direction: ${(p) => p.$isUser && "row-reverse"};
  display: flex;
  gap: 5px;
  width: 100%;
  padding: 5px;
  background: rgba(4, 72, 213, 0.05);
  background: ${(p) => p.$isUser && "rgba(231, 56, 74, 0.06)"};
  /* background: ${(p) => p.$isUser && "rgba(239 213 218, 0.1)"}; */
`;
const StyledMessageContent = styled.div<{ $isUser: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: ${(p) => p.$isUser && "flex-end"};
  gap: 5px;
  font-weight: 600;
  > :first-child {
    font-size: 12px;
    color: ${(p) =>
      p.$isUser ? "rgba(231, 56, 74, 0.76)" : "rgba(4, 72, 213, 0.76)"};
  }
  > :last-child {
    font-size: 14px;
    font-weight: 600;
    /* color: rgba(4, 72, 213, 0.96); */
    color: ${(p) =>
      p.$isUser ? "rgba(231, 56, 74, 0.99)" : "rgba(4, 72, 213, 0.96)"};
  }
  .novoie {
    margin-left: 10px;
    color: #f75364;
  }
`;
const StyledWe = styled.div`
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(4, 72, 213, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledClient = styled(StyledWe)`
  background: rgba(231, 56, 74, 0.16);
  color: rgb(247, 83, 100);
`;
