import styled from "styled-components";
import { media } from "../../ui/config";

export const StyledCallbackWrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 181;
`;
export const StyledCallbackBtn = styled.button<{ $chatIsOpen: boolean }>`
  border: 1px solid
    ${(p) => (p.$chatIsOpen ? "transparent" : "rgba(0, 0, 0, 0.1)")};
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* border: 1px solid red; */
  position: relative;
  outline: none;
  width: 60px;
  height: 60px;
  border-radius: ${(p) => (p.$chatIsOpen ? "12px 0 12px 0" : "50%")};
  transition: all 0.3s;
  background: ${(p) => (p.$chatIsOpen ? "white" : `rgba(4, 72, 213, 1)`)};
  cursor: pointer;
  z-index: 144;

  &:hover {
    transform: ${(p) => !p.$chatIsOpen && "scale(1.05)"};
    background: ${(p) => p.$chatIsOpen && "#e5eefd"};
  }

  #chat-badge {
    transition: all 0.3s;
    opacity: ${(p) => (p.$chatIsOpen ? "0" : "1")};
  }
  .icons-wrapper {
    position: relative;

    > svg {
      position: absolute;
      top: -20px;
      left: 10px;
      transition: all 0.3s;
    }

    > :first-child {
      opacity: ${(p) => (p.$chatIsOpen ? "0" : "1")};
      transform: ${(p) => (p.$chatIsOpen ? "scale(0)" : "scale(1)")};
    }

    > :last-child {
      rotate: 45deg;
      top: -14px;
      left: 12px;
      color: rgba(4, 72, 213, 1);
      opacity: ${(p) => (!p.$chatIsOpen ? "0" : "1")};
      transform: ${(p) => (!p.$chatIsOpen ? "scale(0)" : "scale(1)")};
    }
  }
`;
export const StyledBadge = styled.div`
  position: absolute;
  background: rgb(250, 116, 45);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: -12px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 900;
`;
export const StyledChatContent = styled.div<{ $chatIsOpen: boolean }>`
  width: 400px;
  height: 60svh;
  background: white;
  position: absolute;
  bottom: 3px;
  right: 0;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.14);
  transition: opacity 0.3s;
  max-width: ${(p) => (p.$chatIsOpen ? "400px" : "0px")};
  max-height: ${(p) => (p.$chatIsOpen ? "60svh" : "0px")};
  opacity: ${(p) => (p.$chatIsOpen ? "1" : "0")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  gap: 10px;
  z-index: 133;

  ${media.mobileL`
    width: 100vw;
    max-width: ${(p) => (p.$chatIsOpen ? "100vw" : "0px")};
    right: -20px;
    bottom: -20px;
    border-radius: 0;
    z-index: 111111;
    height: 100svh;
    max-height: 100svh;
    max-height: ${(p) => (p.$chatIsOpen ? "100svh" : "0px")};
  `}
`;
export const StyledBottom = styled.div`
  min-height: 58px;
  width: calc(100% - 65px);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${media.mobileL`
    min-height: 70px;
    width: 100%;
    `}

  input {
    height: 40px;
    padding: 0 10px;
    border-radius: 0 12px 0 12px;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;
    background: none;
    font-size: 14px;

    width: 100%;
    position: absolute;
    bottom: 0;
    ${media.mobileL`
      padding-right: 60px;
      height: 50px;
      font-size: 16px;
    `}
  }
  span {
    font-size: 12px;
    font-weight: 600;
    color: rgb(68, 193, 82);
    margin-left: 5px;
  }
`;
export const StyledCloseBtn = styled.div`
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(4, 72, 213, 0.16);
  color: rgb(4, 72, 213);
  transition: all 0.3s;

  &:hover {
    background: rgba(4, 72, 213, 0.26);
  }
`;
export const StyledHeader = styled.div`
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;
export const StyledColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 20px;
    font-weight: 900;
    color: rgb(4, 72, 213);
  }
`;
export const CallbackNewMessages = styled.div`
  position: absolute;
  z-index: 122;
  right: 0;
  bottom: 70px;
  width: 200px;
  height: 40px;
  overflow: hidden;
  background: rgba(4, 72, 213, 1);
  border-radius: 100000px;
  color: rgb(200, 219, 251);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  transition: opacity 0.3s;
  opacity: 0;
  max-width: 0px;
  max-height: 0px;
`;
export const StyledMobileSubmit = styled.button`
  width: 44px;
  height: 44px;
  background: none;
  border: none;
  outline: none;
  display: none;
  position: absolute;
  bottom: 2px;
  right: 3.5px;
  border-radius: 12px;

  align-items: center;
  justify-content: center;
  background: rgba(4, 72, 213, 0.16);
  color: rgb(4, 72, 213);
  >svg {
    rotate: 45deg;
    margin-right: 5px;
  }
  ${media.mobileL`
    display: flex;
  `}
`;
