import { useEffect, useState } from "react";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { useUnmount } from "react-use";

export const useDisablePageScroll = (
  targetElement: HTMLElement | null,
  disable: boolean
) => {
  const [_, setChanger] = useState(1);
  useEffect(() => {
    // setChanger(2);
    if (!targetElement) return;
    if (disable) {
      console.log("hasav");

      disableBodyScroll(targetElement, { reserveScrollBarGap: true });
    } else {
      clearAllBodyScrollLocks();
    }
    // setChanger(3);
  }, [disable, targetElement]);

  useUnmount(clearAllBodyScrollLocks);
};
