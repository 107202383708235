import { memo, useCallback } from "react";
import {
  RouteImage,
  StyledButtons,
  StyledCartItem,
  StyledPricesRow,
  StyledTitle,
} from "./styles";
import { IRoute, LOCAL_CURRENCIES } from "../../interfaces";
import useLocal from "../../hooks/useLocal";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../contexts";
import { OldAndNewPrice } from "../OldAndNewPrice";
import Button from "../Button";

interface RouteItemProps {
  route: IRoute;
}

export const RouteItem = memo((props: RouteItemProps) => {
  const { route } = props;
  const { t } = useLocal();
  const navigate = useNavigate();
  const { setActiveReservation } = useApp();
  const onBuy = useCallback(() => {
    setActiveReservation(route.name);
    navigate("/reservation");
  }, [navigate, route.name, setActiveReservation]);
  const onCheckInfo = useCallback(() => {
    navigate("/route/" + route.name);
    window.scrollTo({ top: 0 });
  }, [navigate, route.name]);
  return (
    <StyledCartItem>
      <StyledTitle>
        {t(route.from)} — {t(route.to)}
      </StyledTitle>
      <RouteImage src={route.img} alt={route.name} />
      <StyledPricesRow>
        <OldAndNewPrice currency={LOCAL_CURRENCIES.amd} price={route.amd} />
        /
        <OldAndNewPrice currency={LOCAL_CURRENCIES.rub} price={route.rub} />
      </StyledPricesRow>
      <StyledButtons>
        <Button
          customStyles={{
            background: "rgba(6,86,254,1)",
            width: "100%",
            cursor: "pointer",
          }}
          content={
            <span style={{ fontSize: 16, fontWeight: 600 }}>
              {t("buy_ticket")}
            </span>
          }
          theme="primary"
          onClick={onBuy}
        />
        <Button
          customStyles={{
            // background: "#fa742d",
            width: "100%",
            cursor: "pointer",
            border: "1px solid rgba(6,86,254,1)",
          }}
          content={
            <span
              style={{
                fontSize: 16,
                fontWeight: 500,
                color: "rgba(6,86,254,1)",
              }}
            >
              {t("check_details")}
            </span>
          }
          theme="primary"
          onClick={onCheckInfo}
        />
      </StyledButtons>
    </StyledCartItem>
  );
});
RouteItem.displayName = "RouteItem";
