import { memo, useCallback, useRef, useState } from "react";
import {
  CallbackNewMessages,
  StyledBadge,
  StyledBottom,
  StyledCallbackBtn,
  StyledCallbackWrapper,
  StyledChatContent,
  StyledCloseBtn,
  StyledColumn,
  StyledHeader,
  StyledMobileSubmit,
} from "./styles";
import { FcAssistant } from "react-icons/fc";
import { BsSendFill } from "react-icons/bs";

import { useMount, useWindowSize } from "react-use";

import { useApp } from "../../contexts";
import useLocal from "../../hooks/useLocal";
import { IoClose } from "react-icons/io5";
import { MessagesList } from "./MessagesList";
import { useDisablePageScroll } from "../../hooks/useDisablePageScroll";

export const Callback = memo(() => {
  const { initializeChat, chat } = useApp();
  useMount(() => initializeChat());
  const { width } = useWindowSize();
  const { t } = useLocal();
  const inputRef = useRef<HTMLInputElement>(null);

  const [chatIsOpen, setChatIsOpen] = useState(false);

  const { sendMessageFromUser } = useApp();
  const [inputValue, setInputValue] = useState("");

  const listRef = useRef<HTMLDivElement>(null);

  useDisablePageScroll(listRef.current, chatIsOpen && width <= 568);

  const callbackBtnClick = useCallback(async () => {
    const list = document.querySelector("#messages-list");

    if (!chatIsOpen) {
      setChatIsOpen(true);
      if (width > 568) inputRef.current?.focus();
      list?.scrollTo({ top: 100000000000000 });
      return;
    }
    if (inputValue.trim()) {
      await sendMessageFromUser(inputValue.trim());

      setInputValue("");

      list?.scrollTo({ top: 100000000000000, behavior: "smooth" });
    }
  }, [chatIsOpen, inputValue, sendMessageFromUser, width]);

  return (
    <StyledCallbackWrapper>
      <CallbackNewMessages className="callback-new-messages">
        {t("you_have_a_new_message")}
      </CallbackNewMessages>
      <StyledCallbackBtn $chatIsOpen={chatIsOpen} onClick={callbackBtnClick}>
        <div className="icons-wrapper">
          <FcAssistant size={40} />
          <BsSendFill size={30} />
        </div>
        {chat.newMessages.length ? (
          <StyledBadge id="chat-badge">{chat.newMessages.length}</StyledBadge>
        ) : null}
      </StyledCallbackBtn>
      <StyledChatContent $chatIsOpen={chatIsOpen}>
        <StyledHeader>
          <StyledColumn>
            <span>Online chat</span>
            <div
              style={{
                width: 10,
                height: 10,
                background: "rgb(68, 193, 82)",
                borderRadius: "50%",
                marginTop: 2,
              }}
            />
          </StyledColumn>
          <StyledCloseBtn onClick={() => setChatIsOpen(false)}>
            <IoClose size={24} />
          </StyledCloseBtn>
        </StyledHeader>
        <MessagesList chat={chat} ref={listRef} />
        <StyledBottom>
          <span>{t("chat_input_label")}</span>
          <input
            ref={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            type="text"
            placeholder={t("write_a_message")}
          />

          <StyledMobileSubmit onClick={callbackBtnClick}>
            {" "}
            <BsSendFill size={25} />
          </StyledMobileSubmit>
        </StyledBottom>
      </StyledChatContent>
    </StyledCallbackWrapper>
  );
});
