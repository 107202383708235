import styled from "styled-components";
import { media } from "../../ui/config";

export const StyledReservationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding-top: 20px;
`;
export const StyledStep1 = styled.div<{ isCheckout: boolean }>`
  width: 100%;
  display: flex;

  gap: 20px;
  justify-content: space-between;

  ${media.tabletL`
    flex-direction: column;

    >div {
      width: 100% !important;
    }
  `}
`;
export const StyledRow = styled(StyledStep1)`
  flex-direction: row;
  gap: 50px;
  justify-content: space-between;

  ${media.tablet`
    flex-direction: column;
    gap: 20px;
  `}
`;
export const Label = styled.span`
  font-size: 20px;
  color: white;
  text-align: center;

  ${media.mobileL`
    font-size: 16px;
  `}
`;
export const StyledModalColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const StyledModalRow = styled.div`
  display: flex;
  width: 100%;
  gap: 50px;
  align-items: center;

  #recaptcha {
    margin-top: 10px;
    /* > div {
      height: 0 !important;
    } */
  }
`;
export const StyledImg = styled.img`
  height: 170px;
  width: fit-content;
  object-fit: contain;

  ${media.tabletL`
    display: none;
  `}
`;
export const PriceContent = styled.div`
  width: 30%;
  background: white;
  border-radius: 16px;
  position: relative;
  padding: 20px;
  /* margin-top: 40px; */
  height: fit-content;
  display: flex;
  flex-direction: column;

  ${media.tabletL`
    position: fixed;
    bottom: 0;
    left: 0;
    
    z-index: 200000000;
    border-top: 1px solid rgba(0,0,0,0.1);
    border-radius: 0;
    zoom: 0.75;
    >:first-child {
      background: white !important;
      border: 1px solid rgba(0,0,0,0.2);
    }
  `}

  ${media.mobileL`
    margin-top: 20px;
  `}

  .info-icon {
    min-width: 20px;
    min-height: 20px;
  }
`;
export const StyledMobileBlock = styled.div`
  ${media.tabletL`
    height: 250px;
  `}
`;
