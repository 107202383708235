import { memo } from "react";
import { StyledBronirovatOnline } from "./styles";

import bron from "../../images/bron.png";
import useLocal from "../../hooks/useLocal";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../contexts";

export const BronContent = memo(() => {
  const { t } = useLocal();
  const navigate = useNavigate();
  const { setActiveReservation } = useApp();
  return (
    <StyledBronirovatOnline>
      <img src={bron} alt="bron" />
      <div
        className="text-content-info"
        style={{
          width: "70%",
          marginLeft: "30%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <span style={{ fontSize: 34, fontWeight: 600, color: "white" }}>
          {t("bron_is_5_min_1")} <span style={{ fontWeight: 900 }}>5</span>{" "}
          {t("bron_is_5_min_2")}!
        </span>
        <span style={{ color: "white", fontSize: 20, marginTop: 20 }}>
          {t("soc_seti_1")} <span style={{ fontWeight: 900 }}>Viber</span>,{" "}
          <span style={{ fontWeight: 900 }}>Telegram</span>,{" "}
          <span style={{ fontWeight: 900 }}>WhatsApp</span> {t("soc_seti_2")}{" "}
          <br />
          <span style={{ fontWeight: 900, letterSpacing: 0.7 }}>
            +374 93 604-808<span style={{ fontWeight: 400 }}>,</span> +7 904
            090-99-59
          </span>
        </span>
        <div className="hr">
          {" "}
          <span>{t("or")}</span>
        </div>
        <span style={{ color: "white", fontSize: 20, marginTop: 20 }}>
          <span className="web-block">
            {t("online_bron_1")}{" "}
            <span style={{ fontWeight: 900 }}>{t("online_bron_2")}</span>{" "}
            {t("and")} {""}
          </span>
          <span className="web-block">
            {t("online_bron_3")}{" "}
            <span style={{ fontWeight: 900 }}>{t("online")}</span>
          </span>
          <Button
            customStyles={{
              border: "1px solid hsla(0,0%,100%,.3)",
              minHeight: 50,
              padding: "0 30px",
              marginTop: 10,
            }}
            content={
              <span style={{ fontSize: 18, fontWeight: 900 }}>
                {t("bron_online")}
              </span>
            }
            onClick={() => {
              setActiveReservation("");
              navigate("/reservation");
            }}
          />
        </span>
      </div>
    </StyledBronirovatOnline>
  );
});
