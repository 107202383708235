import { ITranslate } from "../interfaces";

export const translates: ITranslate[] = [
  {
    key: "nav_slogan",
    label_am: "Հարմարավետ մինիվեններ, անվտանգ ճանապարհ և որակյալ սպասարկում",
    label_ru: "Комфортные минивэны, безопасная дорога и качественный сервис",
  },
  {
    key: "nav_phone",
    label_am: "Հեռ",
    label_ru: "Тел",
  },
  {
    key: "menu_main",
    label_ru: "Главное",
    label_am: "Գլխավոր",
  },
  {
    key: "menu_routes",
    label_ru: "Маршруты",
    label_am: "Երթուղիներ",
  },
  {
    key: "menu_about",
    label_ru: "О нас",
    label_am: "Մեր մասին",
  },
  {
    key: "search_city",
    label_ru: "Искать маршрут",
    label_am: "Որոնել երթուղի",
  },
  {
    key: "krasnodar",
    label_ru: "Краснодар",
    label_am: "Կրասնոդար",
  },
  {
    key: "yerevan",
    label_ru: "Ереван",
    label_am: "Երևան",
  },
  {
    key: "moscow",
    label_ru: "Москва",
    label_am: "Մոսկվա",
  },
  {
    key: "rostov",
    label_ru: "Ростов на Дону",
    label_am: "Դոնի Ռոստով",
  },
  {
    key: "not_found",
    label_ru: "Маршрут не найден",
    label_am: "Երթուղին չի գտնվել",
  },
  {
    key: "krasnoyarsk",
    label_ru: "Красноярск",
    label_am: "Կրասնոյարսկ",
  },
  {
    key: "fromArmeniaToRussia",
    label_ru: "Армения - Россия",
    label_am: "Հայաստան - Ռուսաստան",
  },
  {
    key: "phone",
    label_ru: "Тел",
    label_am: "Հեռ",
  },
  {
    key: "header_label_1",
    label_ru: "Скидка на",
    label_am: "Զեղչ",
  },
  {
    key: "header_label_2",
    label_ru: "билетов",
    label_am: "տոմսի համար",
  },
  {
    key: "header_label_2",
    label_ru: "билетов",
    label_am: "տոմսի համար",
  },
  {
    key: "home_title",
    label_ru: "Пассажироперевозки",
    label_am: "Ուղևորափոխադրումներ",
  },
  {
    key: "watch_routes",
    label_ru: "Посмотреть маршруты",
    label_am: "Տեսնել երթուղիները",
  },
  {
    key: "check_details",
    label_ru: "Посмотреть подробности",
    label_am: "Մանրամասներ",
  },
  {
    key: "soc_seti_1",
    label_ru: "Написать на соц. сети",
    label_am: "Գրել սոց. ցանցերին",
  },
  {
    key: "help_first_message",
    label_am: "Ողջույն! Ինչպե՞ս կարող եմ օգնել ձեզ:",
    label_ru: "Привет! Как я могу помочь вам?",
  },
  {
    key: "soc_seti_2",
    label_ru: "на номера",
    label_am: "այս համարներով",
  },
  {
    key: "bron_is_5_min_1",
    label_ru: "Бронирование билета займет",
    label_am: "Տոմսերի ամրագրումը կտևի",
  },
  {
    key: "bron_billet",
    label_ru: "Бронирование билета",
    label_am: "Տոմսերի ամրագրում",
  },
  {
    key: "bron_is_5_min_2",
    label_ru: "минут",
    label_am: "րոպե",
  },
  {
    key: "or",
    label_ru: "или",
    label_am: "կամ",
  },
  {
    key: "learn_more",
    label_ru: "Узнать больше",
    label_am: "Իմանալ ավելին",
  },
  {
    key: "go_back",
    label_ru: "Вернуться назад",
    label_am: "Վերադառալ հետ",
  },
  {
    key: "travel_time",
    label_ru: "Время в пути",
    label_am: "Տևողությունը",
  },
  {
    key: "hand_luggage",
    label_ru: "Ручная кладь",
    label_am: "Ձեռքի ուղեբեռը",
  },
  {
    key: "kilo",
    label_ru: "кг",
    label_am: "կգ",
  },
  {
    key: "hours",
    label_ru: "часов",
    label_am: "ժամ",
  },
  {
    key: "new_message",
    label_am: "Նոր հաղորդագրություն!",
    label_ru: "Новое сообщение!",
  },
  {
    key: "baggage_normal",
    label_ru: "Норма багажа",
    label_am: "Ուղեբեռի նորման",
  },
  {
    key: "chat_input_label",
    label_ru: "Оператор свяжется с вами в ближайшее время.",
    label_am: "Մեր օպերատորը շուտով կկապվի ձեզ հետ:",
  },
  {
    key: "write_a_message",
    label_ru: "Пишите сообщение...",
    label_am: "Գրեք հաղորդագրություն...",
  },
  {
    key: "place",
    label_ru: "место",
    label_am: "տեղ",
  },
  {
    key: "baggage_excess",
    label_ru: "Багаж сверх нормы",
    label_am: "Ավելորդ ուղեբեռ",
  },
  {
    key: "dram",
    label_ru: "драм",
    label_am: "դրամ",
  },
  {
    key: "more_info_1",
    label_ru:
      "По пути следования через каждые 2-3 часа предусмотрены остановки возле придорожных кафе",
    label_am:
      "Ուղևորության ժամանակ ավտոբուսը պարբերաբար՝ ամեն 2-3 ժամը մեկ, կատարում է կանգառներ ճամփեզրին գտնվող սրճարանների մոտ",
  },
  {
    key: "more_info_2",
    label_ru:
      "Продолжительность остановок от 30 до 40 минут, этого времени вполне достаточно для того, чтобы выпить чашку кофе или чая, перекусить, сходить в туалет или просто размяться после длительного сидения",
    label_am:
      "Կանգառների տևողությունը կազմում է 30-40 րոպե: Այդ ընթացքում դուք կհասցնեք օգտվել սանհանգույցից, ինչ-որ բան ուտել, սուրճ, թեյ խմել կամ մի փոքր հանգստանալ երկարատև նստած ճանապարհորդությունից",
  },
  {
    key: "more_info_3",
    label_ru:
      "Сразу при заказе 7 билетов предоставляется скидка в 10 процентов на каждый билет",
    label_am:
      "Միանգամից 7 տոմս պատվիրելու դեպքում՝ յուրաքանչյուր տոմսի համար տրամադրվում է 10 տոկոս զեղչ",
  },
  {
    key: "more_info_4",
    label_ru:
      "Мы свяжемся с Вами в течение одного рабочего дня после получения заявки, чтобы уточнить дату отправки, конкретное место и другие детали",
    label_am:
      "Մենք ձեզ հետ կկապվենք դիմումը ստանալուց հետո մեկ աշխատանքային օրվա ընթացքում՝ ճշտելու ուղեւորման ամսաթիվը, կոնկրետ վայրը և այլ մանրամասներ:",
  },
  {
    key: "more_info_5",
    label_ru:
      "Оформить заказ можно сразу, позвонив по номеру +374 (93) 604-808",
    label_am:
      "Կարող եք անմիջապես պատվիրել՝ զանգահարելով +374 (93) 604-808 հեռախոսահամարով",
  },
  {
    key: "buy_ticket",
    label_ru: "Забронировать билет",
    label_am: "Ամրագրել տոմս",
  },
  {
    key: "miniralnie_vodi",
    label_ru: "Минеральные Воды",
    label_am: "Մինվոդի",
  },
  {
    key: "rostov",
    label_ru: "Ростов",
    label_am: "Ростов",
  },
  {
    key: "vladikavkaz",
    label_ru: "Владикавказ",
    label_am: "Վլադիկավկազ",
  },
  {
    key: "voronej",
    label_ru: "Воронеж",
    label_am: "Վորոնեժ",
  },
  {
    key: "belgorod",
    label_ru: "Белгород",
    label_am: "Բելգորոդ",
  },
  {
    key: "alekseevka",
    label_ru: "Алексеевка",
    label_am: "Ալեքսեևկա",
  },
  {
    key: "valuyki",
    label_ru: "Валуйки",
    label_am: "Վալույկի",
  },
  {
    key: "kursk",
    label_ru: "Курск",
    label_am: "Կուրսկ",
  },
  {
    key: "stary_oskol",
    label_ru: "Старый Оскол",
    label_am: "Ստարի Օսկոլ",
  },
  {
    key: "noyemberyan",
    label_ru: "Ноемберян",
    label_am: "Նոյեմբերյան",
  },
  {
    key: "ijevan",
    label_ru: "Иджеван",
    label_am: "Իջեւան",
  },
  {
    key: "dilijan",
    label_ru: "Дилижан",
    label_am: "Դիլիջան",
  },
  {
    key: "sevan",
    label_ru: "Севан",
    label_am: "Սևան",
  },
  {
    key: "abovyan",
    label_ru: "Абовян",
    label_am: "Աբովյան",
  },
  {
    key: "gyumri",
    label_ru: "Гюмри",
    label_am: "Գյումրի",
  },
  {
    key: "from",
    label_ru: "Откуда",
    label_am: "Սկզբնակետ",
  },
  {
    key: "persons_count",
    label_ru: "Количество билет",
    label_am: "Տոմսերի քանակը",
  },
  {
    key: "to",
    label_ru: "Куда",
    label_am: "Վերջնակետ",
  },
  {
    key: "name",
    label_ru: "Имя",
    label_am: "Անուն",
  },
  {
    key: "surname",
    label_ru: "Фамилия",
    label_am: "Ազգանուն",
  },
  {
    key: "enter_name",
    label_ru: "Введите свое имя",
    label_am: "Մուտքագրեք ձեր անունը",
  },
  {
    key: "enter_surname",
    label_ru: "Введите свою фамилию",
    label_am: "Մուտքագրեք ձեր ազգանունը",
  },
  {
    key: "number",
    label_ru: "Номер",
    label_am: "Համար",
  },
  {
    key: "select_value",
    label_ru: "Выбрать город",
    label_am: "Ընտրել քաղաք",
  },
  {
    key: "from_total_cost",
    label_ru: "от общей цены",
    label_am: "от общей цены",
  },
  {
    key: "ticket_count",
    label_ru: "Кол-во билетов",
    label_am: "Տոմսերի քանակը",
  },
  {
    key: "ticket",
    label_ru: "билет",
    label_am: "Տոմս",
  },
  {
    key: "for_example",
    label_ru: "Напр.",
    label_am: "Օրինակ",
  },
  {
    key: "km",
    label_ru: "км.",
    label_am: "км.",
  },
  {
    key: "hour",
    label_ru: "час",
    label_am: "ժամ",
  },
  {
    key: "error_send",
    label_ru: "Нужно заполнить все поля!",
    label_am: "Պետք է լրացվեն բոլոր դաշտերը!",
  },
  {
    key: "success_send",
    label_ru: "Ваша заявка отправлена!",
    label_am: "Ձեր դիմումն ուղարկված է!",
  },
  {
    key: "rub",
    label_am: "Ռուբլի",
    label_ru: "Рублей",
  },
  {
    key: "amd",
    label_am: "Դրամ",
    label_ru: "Драм",
  },
  {
    key: "you_have_a_new_message",
    label_am: "Դուք նոր նամակ ունեք:",
    label_ru: "У вас новое сообщение!",
  },
  {
    key: "name_surname",
    label_am: "Անուն, ազգանուն",
    label_ru: "Имя, фамилия",
  },
  {
    key: "phone_number",
    label_am: "Հեռախոսահամար",
    label_ru: "Номер телефона",
  },
  {
    key: "phone_number_step_1",
    label_am: "Հեռախոսահամար",
    label_ru: "Номер телефона",
  },
  {
    key: "code_sended_on",
    label_am: "Կոդն ուղարկվել է համարին",
    label_ru: "Код отправлен на номер",
  },
  {
    key: "modal_content_1",
    label_ru: "Мы с вами свяжемся в скором времени для оформления заказа.",
    label_am: "Մենք շուտով կկապվենք ձեզ հետ՝ ձեր պատվերը տեղադրելու համար:",
  },
  {
    key: "send_code",
    label_am: "Հաստատել տվյալները",
    label_ru: "Подтвердить данные",
  },
  {
    key: "type_6_code",
    label_ru: "Введите 6-ти значный код ниже",
    label_am: "Ներքևում մուտքագրեք 6 նիշանոց կոդը",
  },
  { key: "wrong_code", label_ru: "Неправильный код", label_am: "Սխալ կոդ" },
  {
    key: "verify_code",
    label_ru: "Подтвердить код",
    label_am: "Հաստատել կոդը",
  },
  {
    key: "passengers_count",
    label_am: "Ուղևորների թիվը",
    label_ru: "Число пассажиров",
  },
  {
    key: "send",
    label_am: "Ուղարկել",
    label_ru: "Отправить",
  },
  {
    key: "passanger",
    label_am: "ուղևոր",
    label_ru: "пассажир",
  },
  {
    key: "passangers",
    label_am: "ուղևոր",
    label_ru: "пассажиров",
  },
  {
    key: "person",
    label_am: "Անձ",
    label_ru: "Человек",
  },
  {
    key: "armenia",
    label_ru: "Армения",
    label_am: "Հայաստան",
  },
  {
    key: "russia",
    label_ru: "Россия",
    label_am: "Ռուսաստան",
  },
  {
    key: "select_route_for_calculate_price",
    label_ru: "Выберите города для просмотра цены",
    label_am: "Ընտրեք քաղաքները՝ գները դիտելու համար",
  },
  {
    key: "fill_all_fields",
    label_ru: "Заполните все поля",
    label_am: "Լրացրեք բոլոր դաշտերը",
  },
  {
    key: "online_bron_1",
    label_ru: "Подтвердить",
    label_am: "Հաստատել",
  },
  {
    key: "online_bron_2",
    label_ru: "номер телефона",
    label_am: "հեռախոսահամար",
  },
  {
    key: "step_1_fixed_1",
    label_ru:
      "Точная дата поездки может отличаться от выбранной. Окончательная дата будет согласована с нашим оператором при обсуждении!",
    label_am:
      "Ճանապարհորդության ճշգրիտ ամսաթիվը կարող է տարբերվել ընտրվածից: Վերջնական ամսաթիվը կհամաձայնեցվի մեր օպերատորի հետ քննարկման ժամանակ:",
  },
  {
    key: "step_1_fixed_2",
    label_ru: "и отправить заявку",
    label_am: "",
  },
  {
    key: "and",
    label_ru: "и",
    label_am: "և",
  },
  {
    key: "online_bron_3",
    label_ru: "забронировать",
    label_am: "ամրագրել",
  },
  {
    key: "online",
    label_ru: "онлайн",
    label_am: "օնլայն",
  },
  {
    key: "bron_online",
    label_ru: "Забронировать онлайн",
    label_am: "Ամրագրել օնլայն",
  },
  {
    key: "our_cars",
    label_ru: "Наши машины",
    label_am: "Մեր մեքենաները",
  },
  { key: "see_all", label_ru: "Посмотреть все", label_am: "Դիտել բոլորը" },
  {
    key: "our_routes",
    label_ru: "Наши маршруты",
    label_am: "Մեր երթուղիները",
  },
  {
    key: "helpful_information",
    label_ru: "Полезная информация",
    label_am: "Օգտակար տեղեկատվություն",
  },
  {
    key: "transportnie_passajiroperevozki",
    label_ru: "транспортные пассажироперевозки",
    label_am: "տրանսպորտային ուղևորափոխադրումներ",
  },
  {
    key: "info_1_1",
    label_ru: "Комфортные условия для поездок",
    label_am: "Հարմարավետ պայմաններ",
  },
  {
    key: "info_1_2",
    label_ru: "кондиционер",
    label_am: "օդորակիչ",
  },
  {
    key: "select_date",
    label_ru: "Дата поездки",
    label_am: "Ամսաթիվ",
  },
  {
    key: "day",
    label_ru: "День",
    label_am: "Օր",
  },
  {
    key: "month",
    label_ru: "Месяц",
    label_am: "Ամիս",
  },
  {
    key: "year",
    label_ru: "Год",
    label_am: "Տարի",
  },
  {
    key: "open_calendar",
    label_ru: "Открыть календарь",
    label_am: "Բացել օրացույցը",
  },
  {
    key: "info_1_3",
    label_ru: "пересадки каждые",
    label_am: "կանգառ ամեն",
  },
  {
    key: "info_1_4",
    label_ru: "часа",
    label_am: "ժամը մեկ",
  },
  {
    key: "info_2_1",
    label_ru: "успешных поездок",
    label_am: "հաջող ուղևորափոխադրումներ",
  },
  {
    key: "info_2_2",
    label_ru: "довольных клиентов",
    label_am: "գոհ հաճախորդներ",
  },
  {
    key: "info_3_1",
    label_ru: "Проверенные водители со стажем",
    label_am: "Ստուգված և փորձառու վարորդներ",
  },
  {
    key: "info_3_2",
    label_ru: "лет",
    label_am: "տարի փորձով",
  },
  {
    key: "info_4_1",
    label_ru: "Бесплатное перемещение груза до",
    label_am: "Անվճար բեռնափոխադրումներ մինչև",
  },
  {
    key: "kg",
    label_ru: "кг",
    label_am: "կգ",
  },
  {
    key: "rusarm_title",
    label_ru: "Пассажироперевозки Армения - Россия",
    label_am: "Ուղևորափոխադրումներ Հայաստան - Ռուսաստան",
  },
  {
    key: "rusarm_home_description",
    label_ru:
      "Комфортные и безопасные пассажирские перевозки из Армении в Россию. Мы предлагаем регулярные рейсы, удобные маршруты и профессиональное обслуживание. Забронируйте свое путешествие с нами и наслаждайтесь поездкой.",
    label_am:
      "Հարմարավետ և անվտանգ ուղևորափոխադրումներ Հայաստանից Ռուսաստան։ Մենք առաջարկում ենք կանոնավոր երթեր, հարմար երթուղիներ և պրոֆեսիոնալ սպասարկում։ Ամրագրեք ձեր ճանապարհորդությունը մեզ հետ և վայելեք ուղևորությունը։",
  },
  {
    key: "reservation_title",
    label_ru: "Забронировать билет Армения - Россия",
    label_am: "Ամրագրել տոմս Հայաստան - Ռուսաստան",
  },
  {
    key: "reservation_title",
    label_ru: "Забронировать билет Армения - Россия",
    label_am: "Ամրագրել տոմս Հայաստան - Ռուսաստան",
  },
  {
    key: "bus",
    label_am: "ավտոբուս",
    label_ru: "автобус",
  },
  {
    key: "bron_bez_oplati",
    label_am: "Ամրագրում առանց վճարման",
    label_ru: "Бронирование без оплаты",
  },
  {
    key: "for_bron_route_1",
    label_am: "Ամրագրելու համար պարզապես անհրաժեշտ է հաստատել",
    label_ru: "Для бронирование нужно просто подтвердить",
  },
  {
    key: "distance",
    label_am: "Հեռավորությունը",
    label_ru: "Дистанция",
  },
  {
    key: "kilometr",
    label_am: "կմ",
    label_ru: "км",
  },
  {
    key: "view_on_map",
    label_am: "Դիտեք երթուղին քարտեզի վրա",
    label_ru: "Посмотреть маршрут на карте",
  },
  {
    key: "road_on_map",
    label_am: "Երթուղին քարտեզի վրա",
    label_ru: "Маршрут на карте",
  },
  {
    key: "callback",
    label_am: "Հետադարձ կապ",
    label_ru: "Обратная связь",
  },
  {
    key: "callback_description",
    label_ru: "Наши операторы свяжутся с вами в течении часа",
    label_am: "Մեր օպերատորները կկապվեն ձեզ հետ մեկ ժամվա ընթացքում",
  },
  {
    key: "lozung_head",
    label_ru: "ВАШ ПУТЬ",
    label_am: "ՁԵՐ ՈՒՂԻՆ",
  },
  {
    key: "lozung",
    label_ru: "К НОВЫМ УСПЕХАМ!",
    label_am: "ԴԵՊԻ ՀԱՋՈՂՈՒԹՅՈՒՆ!",
  },
  {
    key: "select_direction",
    label_ru: "Выберите направление",
    label_am: "Ընտրեք ուղղությունը",
  },
  {
    key: "from_armenia_to_russia",
    label_ru: "Из Армении в Россию",
    label_am: "Հայաստանից Ռուսաստան",
  },
  {
    key: "from_russia_to_armenia",
    label_ru: "Из России в Армению",
    label_am: "Ռուսաստանից Հայաստան",
  },
  {
    key: "select_city",
    label_ru: "Выбрать город",
    label_am: "Ընտրել քաղաք",
  },
  {
    key: "your_verification_code",
    label_ru: "Ваш верификационный код",
    label_am: "Ձեր հաստատման կոդը",
  },
  {
    key: "bron_success_text",
    label_ru:
      "Бронирование успешно оформлено! В ближайшее время наш оператор свяжется с вами.",
    label_am:
      "Ամրագրումը հաջողությամբ ավարտվեց: Մեր օպերատորը շուտով կկապվի ձեզ հետ:",
  },
  {
    key: "bron_title",
    label_ru: "Бронирование",
    label_am: "Ամրագրում",
  },
  {
    key: "all_routes",
    label_ru: "Все направления",
    label_am: "Բոլոր ուղղությունները",
  },
  {
    key: "alternative_content",
    label_am:
      "Եթե ​​ձեր երթուղին չի գտնվել մեր ցուցակում, խնդրում ենք կապվել մեզ հետ. մենք կարող ենք ունենալ համապատասխան այլընտրանքներ:",
    label_ru:
      "Если ваш маршрут не найден в нашем списке, свяжитесь с нами — возможно, у нас есть подходящие альтернативы!",
  },
];
