import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { TbRoute, TbShoppingBagCheck, TbShoppingBagPlus } from "react-icons/tb";
import styled, { keyframes } from "styled-components";
import { AiOutlineFieldTime } from "react-icons/ai";
import { FaqInfo } from "../FaqInfo";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Map, RouteButton, YMaps } from "react-yandex-maps";
import { GiClick } from "react-icons/gi";

import ReactDOM from "react-dom";
import { useWindowSize } from "react-use";
import { OurCars } from "../OurCars";
import { useApp } from "../../contexts";
import { useNavigate, useParams } from "react-router-dom";
import useLocal from "../../hooks/useLocal";
import Button from "../Button";
import { OldAndNewPrice } from "../OldAndNewPrice";
import { LOCAL_CURRENCIES } from "../../interfaces";
import { media } from "../../ui/config";
import { translates } from "../../translates";

export const RouteComponent = memo(() => {
  const { findRoute, setActiveReservation } = useApp();
  const { name } = useParams<{ name: string }>();
  const route = findRoute(name || "");
  const { t, language } = useLocal();
  const [isLoaded, setIsLoaded] = useState(false);
  const [mapIsLoading, setMapIsLoading] = useState(true);
  const { width } = useWindowSize();
  const isTabletL = useMemo(() => width <= 991, [width]);
  const [mobileLoading, setMobileLoading] = useState(false);

  const routePoints = useMemo(() => {
    const from = translates.find((i) => i.key === route.from);
    const to = translates.find((i) => i.key === route.to);
    return [`${from?.label_ru}, Армения`, `${to?.label_ru}, Россия`];
  }, [route.from, route.to]);

  const scrollToMap = useCallback(() => {
    window.scrollTo({ behavior: "smooth", top: 5000 });
  }, []);

  const x = useCallback(async () => {
    const wrapper = document.querySelector(".ymaps-2-1-79-route-panel-button");
    const routeButton = wrapper?.querySelector(".ymaps-2-1-79-float-button");
    if (routeButton && isTabletL) {
      const iconContainer = document.createElement("div");
      const textContent = document.createElement("span");
      routeButton.innerHTML = "";
      textContent.textContent = "Посмотреть маршрут";
      textContent.style.color = "white";
      textContent.style.fontWeight = "900";
      textContent.style.fontSize = "24px";
      textContent.style.textAlign = "center";
      routeButton.appendChild(iconContainer);
      routeButton.appendChild(textContent);
      ReactDOM.render(<GiClick size={100} color="white" />, iconContainer);
    }

    //@ts-ignore
    routeButton?.click();
  }, [isTabletL]);

  useEffect(() => {
    if (!isLoaded) return;
    setTimeout(() => {
      x();
    }, 1000);
  }, [isLoaded, x]);

  useEffect(() => {
    const interval = setInterval(() => {
      const x = document.querySelector(".ymaps-2-1-79-placemark-overlay");
      if (x) {
        setMapIsLoading(false);
        setMobileLoading(false);
      }
    }, 1000);
    if (!mapIsLoading) clearInterval(interval);
  }, [mapIsLoading]);

  const onMobileClick = () => {
    if (!isTabletL) return;
    setMobileLoading(true);
    const wrapper = document.querySelector(
      ".ymaps-2-1-79-controls-pane"
    ) as HTMLElement;

    if (wrapper) {
      wrapper.style.height = "0px";
    }
  };

  const navigate = useNavigate();

  const onBuy = useCallback(() => {
    setActiveReservation(route.name);
    navigate("/reservation");
  }, [navigate, route.name, setActiveReservation]);

  return (
    <div style={{ paddingBottom: "30px" }}>
      <RouteComponentWrapper>
        <RouteImage src={route.img} alt={route.name} />
        <RouteContent>
          <span
            className="title-route"
            style={{ fontSize: 30, fontWeight: 900 }}
          >
            {t(route.from)} — <br /> {t(route.to)}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              marginTop: 20,
              height: "100%",
              justifyContent: "space-between",
            }}
            className="test"
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <TbRoute size={25} />
              <span style={{ fontSize: 18, marginLeft: 5 }}>
                {t("distance")}:{" "}
                <span style={{ fontWeight: 600, marginLeft: 20 }}>
                  {route.distance} {t("kilometr")}
                </span>
              </span>
            </span>
            <span style={{ display: "flex", alignItems: "center" }}>
              <AiOutlineFieldTime size={25} />
              <span style={{ fontSize: 18, marginLeft: 5 }}>
                {t("travel_time")}:{" "}
                <span style={{ fontWeight: 600, marginLeft: 20 }}>
                  {route.time} {t("hour")}
                </span>
              </span>
            </span>
            <span style={{ display: "flex", alignItems: "center" }}>
              <TbShoppingBagCheck size={25} />
              <span style={{ fontSize: 18, marginLeft: 5 }}>
                {t("baggage_normal")}:{" "}
                <span style={{ fontWeight: 600, marginLeft: 20 }}>
                  {20} {t("kg")}
                </span>
              </span>
            </span>
            <span style={{ display: "flex", alignItems: "center" }}>
              <TbShoppingBagPlus size={25} />
              <span style={{ fontSize: 18, marginLeft: 5 }}>
                {t("baggage_excess")}:{" "}
                <span
                  style={{
                    fontWeight: 600,
                    marginLeft: 20,
                    textTransform: "lowercase",
                  }}
                >
                  {language?.value === "am"
                    ? `500 ${t("dram")}/${t("kg")}`
                    : `100 ${t("rub")}/${t("kg")}`}
                </span>
              </span>
            </span>
            <Button
              content={
                <span
                  style={{
                    color: "#f75364",
                    display: "flex",
                    gap: 10,
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  <FaMapMarkedAlt />
                  {t("view_on_map")}
                </span>
              }
              customStyles={{
                background: "rgb(231 56 74 / 16%)",
                width: "fit-content",
                padding: "0 20px",
              }}
              onClick={scrollToMap}
            />
          </div>
        </RouteContent>
        <PriceContent>
          <span
            style={{
              padding: "3px 10px",
              borderRadius: "16px",
              background: "rgb(8 94 253 / 30%)",
              color: "#002669",
              fontWeight: 600,
              position: "absolute",
              top: -10,
              left: "50%",
              transform: "translateX(-50%)",
              fontSize: 14,
              width: "fit-content",
              whiteSpace: "nowrap",
            }}
          >
            {t("bron_bez_oplati")}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              zoom: 1.3,
              paddingTop: 10,
            }}
          >
            <OldAndNewPrice currency={LOCAL_CURRENCIES.amd} price={route.amd} />
            <OldAndNewPrice currency={LOCAL_CURRENCIES.rub} price={route.rub} />
          </div>
          <Button
            onClick={onBuy}
            content={
              <span
                style={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: 20,
                  textTransform: "capitalize",
                }}
              >
                {t("online_bron_3")}
              </span>
            }
            customStyles={{
              border: "1px solid rgba(0,0,0,0.1)",
              width: "100%",
              minHeight: 50,
              marginTop: 20,
              background: "rgb(15 115 255)",
            }}
          />
          <span
            style={{
              fontSize: 13,
              fontWeight: 600,
              lineHeight: "100%",
              marginTop: 10,
            }}
          >
            {t("for_bron_route_1")}{" "}
            <span style={{ fontWeight: 900, textTransform: "lowercase" }}>
              {t("phone_number")}
            </span>
          </span>
        </PriceContent>
      </RouteComponentWrapper>
      <FaqInfo />
      <div style={{ marginTop: 20 }}>
        <OurCars />
      </div>
      <StyledMapWrapper>
        <span
          className="map-title"
          style={{
            fontSize: 30,
            fontWeight: 900,
          }}
        >
          {t("road_on_map")}
        </span>
        <YMaps
          query={{
            load: "package.full",
            apikey: "7ab656bd-9532-492c-86c8-2350d2e13227",
            lang: "ru_RU",
          }}
        >
          <Map
            defaultState={{
              center: [40.187216294321786, 44.51520887882538],
              zoom: 5,
              controls: ["zoomControl"],
            }}
          >
            {(mapIsLoading && !isTabletL) || mobileLoading ? (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: "rgba(0,0,0,0.8)",
                  top: 0,
                  zIndex: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </div>
            ) : null}

            <RouteButton
              options={{
                float: "left",
              }}
              selected={true}
              onClick={onMobileClick}
              onLoad={() => setIsLoaded(true)}
              instanceRef={(ref) => {
                if (ref) {
                  //@ts-ignore
                  ref.routePanel.state.set({
                    fromEnabled: false,
                    from: routePoints[0],
                    toEnabled: false,
                    to: routePoints[routePoints.length - 1],
                  });
                  //@ts-ignore
                  ref.routePanel.options.set({
                    routePanelHeader: "Ваш маршрут",
                    routePanelDescription:
                      "Маршрут из Еревана в Москву через Тбилиси и Владикавказ",
                  });
                  // @ts-ignore
                }
              }}
            />
          </Map>
        </YMaps>
      </StyledMapWrapper>
    </div>
  );
});

RouteComponent.displayName = "RouteComponent";

const RouteComponentWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  ${media.tabletL`
  flex-direction: column;  

  `}
`;
const RouteImage = styled.img`
  height: 250px;
  min-width: 30%;
  object-fit: cover;
  border-radius: 16px;
  ${media.tabletL`
    height: 150px;
  `}
`;
const RouteContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40%;

  .title-route br {
    display: none;

    ${media.mobileL`
      display: block;
    `}
  }

  button {
    ${media.mobileL`
      
      width: 100% !important;

      >span {
        font-size: 12px !important;
      }
    `}
  }
`;
const PriceContent = styled.div`
  width: 100%;
  background: white;
  border-radius: 16px;
  position: relative;
  padding: 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;

  ${media.mobileL`
    margin-top: 20px;
  `}

  .info-icon {
    min-width: 20px;
    min-height: 20px;
  }
`;
const StyledMapWrapper = styled.div`
  width: 100%;
  height: 500px;
  background: red;
  margin-top: 30px;
  border-radius: 16px;
  overflow: hidden;
  padding: 20px;
  background: white;
  gap: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  > :last-child {
    width: 100% !important;
    height: 100% !important;
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .map-title {
    ${media.mobile`
      height: 73px !important;
    `}
  }
  .ymaps-2-1-79-map {
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
  }
  .ymaps-2-1-79-controls-pane {
    height: 0;
    overflow: hidden;
  }
  ${media.tabletL`
    
  .ymaps-2-1-79-controls-pane {
    height: 100%;
    .ymaps-2-1-79-controls__toolbar {
      height: 100%;
      margin-top: 0 !important;
      .ymaps-2-1-79-controls__toolbar_left {
        height: 100%;
        width: 100%;
        .ymaps-2-1-79-controls__control_toolbar {
          margin-left: 0 !important;
          width: 100%;
          height: 100%;

          .ymaps-2-1-79-route-panel-button,
          > :first-child {
            display: block;
            height: 100%;
            width: 100%;
          }
          .ymaps-2-1-79-route-panel-button__button {
            width: 100%;
            height: 100%;
            >:first-child, ymaps {
              width: 100%;
              height: 100%;
              display: block;
              .ymaps-2-1-79-float-button {
                background: rgba(0,0,0,0.5);
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;
                justify-content: center;
                padding-right: 28px;
                position: absolute;
                z-index: 10;
              }
            }
          }
        }
      }
    }
  }
  `}/* .ymaps-2-1-79-controls__toolbar {
    opacity: 0;
  } */
`;
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 5px solid white;
  border-right: 5px solid white;
  border-bottom: 5px solid white;
  border-left: 5px solid transparent;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;
