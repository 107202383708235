import { memo, useState } from "react";
import { Helmet } from "react-helmet-async";
import useLocal from "../../hooks/useLocal";
import { StyledRoutes } from "./styles";
import { useApp } from "../../contexts";
import { RouteItem } from "../../components/RouteItem";
import { BronContent } from "../../components/BronContent";
import { OurCars } from "../../components/OurCars";
import { FaqInfo } from "../../components/FaqInfo";
import { InitLoading } from "../../components/InitLoading";
import { SeeAllRoutes } from "../../components/SeeAllRoutes";

export const MainPage = memo(() => {
  const { t } = useLocal();
  const { routes, isInitLoading, showNewMessages } = useApp();
  const [seeAllIsOpen, setSeeAllOpen] = useState(false);

  return (
    <div>
      {seeAllIsOpen && <SeeAllRoutes onClose={() => setSeeAllOpen(false)} />}
      <Helmet>
        <title>{t("rusarm_title")}</title>
        <meta name="description" content={"description"} />
        <meta property="og:title" content={"title"} />
        <meta property="og:description" content={"description"} />
        {/* Add other meta tags as needed */}
      </Helmet>
      {isInitLoading ? (
        <InitLoading />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 20,
            gap: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 10,
            }}
          >
            <span
              className="our-routes"
              style={{ fontSize: 30, fontWeight: 900 }}
            >
              {t("our_routes")}
            </span>
            <button
              onClick={() => setSeeAllOpen(true)}
              className="our-routes-btn"
            >
              {t("see_all")}
            </button>
          </div>
          <StyledRoutes>
            {routes.map((route) => (
              <RouteItem key={route.name} route={route} />
            ))}
          </StyledRoutes>
        </div>
      )}
      <div id="recaptcha-container"></div>
      <BronContent />
      <OurCars />
      <FaqInfo />
    </div>
  );
});
