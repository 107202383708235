import styled from "styled-components";

export const StyledOTPCode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const StyledErrorMessage = styled.div`
  display: flex;
  margin-left: 5px;
  gap: 5px;
  color: red;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
`;

export const StyledErrorWrapper = styled.div`
  height: 15px;
`;

export const StyledOTPInput = styled.input`
  font-size: 36px;
  border: unset;
  font-weight: 700;
  width: 100%;
  padding: 10px;
  color: black;
  text-align: center;
  border-radius: 6px;
  height: 60px;
  min-width: 52px;
  border: 2px solid transparent;
  transition: all 0.3s;
  background: #f1f1f5;
  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.5);
    background: none;
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.15);
  }
`;
