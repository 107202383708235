import { FC, memo, useCallback, useEffect, useRef, useState } from "react";

import { COUNTRIES_INFORMATION, ICountryInformation } from "./constants";
import * as S from "./styles";
import { useMount } from "react-use";
import { IoChevronDown } from "react-icons/io5";
import useLocal from "../../hooks/useLocal";
import { transformPhoneNumber, useOnClickOutside } from "../../utils";

interface PhoneInputProps {
  label?: string;
  error?: string;
  width?: number;
  countryListDirection?: "top" | "bottom";
  value: string;
  description?: string;
  onChange: (value: string) => void;
  onCountryChange?: (country: string) => void;
  onDelete?: () => void;
  defaultFocused?: boolean;
  activeCountryCode?: keyof typeof COUNTRIES_INFORMATION;
}

export const PhoneInput: FC<PhoneInputProps> = memo((props) => {
  const {
    label,
    error,
    width,
    countryListDirection = "bottom",
    value,
    description,
    onChange,
    onCountryChange,
    onDelete,
    defaultFocused = false,
    activeCountryCode = "am",
  } = props;

  const { t } = useLocal();

  const [countriesListIsOpen, setCountriesListIsOpen] = useState(false);

  const [activeCountry, setActiveCountry] = useState(COUNTRIES_INFORMATION.am);

  useMount(() => {
    const activeCountry = COUNTRIES_INFORMATION[activeCountryCode];
    if (!activeCountry) return;
    setActiveCountry(activeCountry);
  });

  const [inputValue, setInputValue] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const newValue = "+" + value;
    const codeLength = activeCountry.phoneCode.length;
    const phoneNumber = newValue.substring(codeLength);
    setInputValue(phoneNumber);
  }, [activeCountry, value]);

  const onChangeInputValue = useCallback(
    (value: string) => {
      const inputIsEmpty = !inputValue.length;

      if (
        inputIsEmpty &&
        value.length === 1 &&
        value === activeCountry.shortPhoneCode
      )
        return;

      const valueIsPasted = Math.abs(value.length - inputValue.length) > 1;
      let activeCountryOnChange = activeCountry;

      let valuesWithoutCountryPhoneCodes = value;

      if (valueIsPasted) {
        const valueWithPhoneCodePrefix = "+" + value;

        if (valueWithPhoneCodePrefix.length > 1) {
          const foundCountry = Object.values(COUNTRIES_INFORMATION).find(
            (country) => {
              return valueWithPhoneCodePrefix.startsWith(country.phoneCode);
            }
          );
          if (
            activeCountryOnChange.name !== foundCountry?.name &&
            foundCountry &&
            foundCountry.maxLength === value.length
          ) {
            activeCountryOnChange = foundCountry;
            setActiveCountry(activeCountryOnChange);
          }
        }
        const activeCountryPhoneCode = activeCountryOnChange.phoneCode.slice(1);

        const possibleCountryPhoneCode = valuesWithoutCountryPhoneCodes.slice(
          0,
          activeCountryPhoneCode.length - inputValue.length
        );

        if (possibleCountryPhoneCode === activeCountryPhoneCode) {
          const countryPhoneCodeLengthWithoutPrefix =
            activeCountryOnChange.phoneCode.length - 1;
          const valueWithoutCountryPhoneCode =
            valuesWithoutCountryPhoneCodes.substring(
              countryPhoneCodeLengthWithoutPrefix
            );
          valuesWithoutCountryPhoneCodes = valueWithoutCountryPhoneCode;
        } else if (
          valuesWithoutCountryPhoneCodes[0] ===
          activeCountryOnChange.shortPhoneCode
        ) {
          const countryPhoneShortCodeLength =
            activeCountryOnChange.shortPhoneCode.length;
          const valueWithoutCountryPhoneCode =
            valuesWithoutCountryPhoneCodes.substring(
              countryPhoneShortCodeLength
            );
          valuesWithoutCountryPhoneCodes = valueWithoutCountryPhoneCode;
        }
      }

      const remainingNumbersCount =
        activeCountryOnChange.maxLength -
        activeCountryOnChange.phoneCode.slice(1).length;
      const remainingValidPhoneNumber = valuesWithoutCountryPhoneCodes.slice(
        0,
        remainingNumbersCount
      );

      if (
        remainingValidPhoneNumber.length <=
        activeCountryOnChange.maxLength -
          activeCountryOnChange.phoneCode.slice(1).length
      ) {
        if (remainingValidPhoneNumber.length === 1) {
          const newValue = (
            activeCountryOnChange.phoneCode + remainingValidPhoneNumber
          ).slice(1);
          onChange(newValue);
        } else {
          onChange(
            activeCountryOnChange.phoneCode.slice(1) + remainingValidPhoneNumber
          );
        }
      }
    },
    [activeCountry, inputValue, onChange]
  );

  const countriesListRef = useRef<HTMLDivElement>(null);

  const openCountriesList = useCallback(() => setCountriesListIsOpen(true), []);
  const closeCountriesList = useCallback(
    () => setCountriesListIsOpen(false),
    []
  );

  useOnClickOutside({
    ref: countriesListRef,
    handler: closeCountriesList,
    preventCLosedBasedElementDataName: "phone-input",
  });

  const changeActiveCountry = useCallback(
    (country: ICountryInformation) => {
      setActiveCountry(country);
      closeCountriesList();
      setInputValue("");
      onChange("");

      if (onCountryChange) {
        const key = Object.keys(COUNTRIES_INFORMATION).find((countryKey) => {
          const countrySelected =
            COUNTRIES_INFORMATION[
              countryKey as keyof typeof COUNTRIES_INFORMATION
            ];
          return countrySelected.phoneCode === country.phoneCode;
        });
        key && onCountryChange(key);
      }
    },
    [closeCountriesList, onChange, onCountryChange]
  );

  const toggleCountriesList = useCallback(() => {
    if (countriesListIsOpen) {
      closeCountriesList();
    } else {
      openCountriesList();
    }
  }, [closeCountriesList, countriesListIsOpen, openCountriesList]);

  const [fakeBlockValue, setFakeBlockValue] = useState("");

  useEffect(() => {
    const filledValue = transformPhoneNumber(
      inputValue,
      activeCountry.placeholder
    );
    const countryPlaceholder = activeCountry.placeholder;
    const extraValues = countryPlaceholder.substring(filledValue.length);
    setFakeBlockValue(filledValue + extraValues);
  }, [activeCountry.placeholder, inputValue]);

  return (
    <S.StyledPhoneInputWrapper data-name="phone-input" $width={width}>
      {label && <S.StyledLabel>{label}</S.StyledLabel>}
      <S.StyledInputWrapper>
        <S.StyledPhoneInputHeader
          $haveError={Boolean(error)}
          $active={isFocused}
          data-name="phone-input"
        >
          <S.StyledSelectCountry onClick={toggleCountriesList}>
            <S.StyledFlag src={activeCountry.flag} alt={activeCountry.name} />
            <span>{activeCountry.phoneCode}</span>
            <IoChevronDown />
          </S.StyledSelectCountry>
          <S.StyledNumberBlock>
            <S.StyledFakeNumbersBlock>
              {fakeBlockValue}
            </S.StyledFakeNumbersBlock>
            <S.StyledPhoneInput
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={(e) =>
                onChangeInputValue(e.target.value.replace(/\D/g, ""))
              }
              autoComplete="tel"
              value={transformPhoneNumber(
                inputValue,
                activeCountry.placeholder
              )}
              autoFocus={defaultFocused}
              inputMode="tel"
              name="tel"
            />
          </S.StyledNumberBlock>
        </S.StyledPhoneInputHeader>

        <S.StyledCountriesList
          ref={countriesListRef}
          $countryListDirection={countryListDirection}
          $isOpen={countriesListIsOpen}
        >
          {Object.keys(COUNTRIES_INFORMATION).map((country) => {
            const countryInformation =
              country as keyof typeof COUNTRIES_INFORMATION;
            return (
              <S.StyledCountryItem
                $active={
                  activeCountry.name ===
                  COUNTRIES_INFORMATION[countryInformation].name
                }
                onClick={() =>
                  changeActiveCountry(COUNTRIES_INFORMATION[countryInformation])
                }
                key={country}
              >
                <S.StyledFlag
                  src={COUNTRIES_INFORMATION[countryInformation].flag}
                  alt={COUNTRIES_INFORMATION[countryInformation].name}
                />
                <S.StyledName>
                  {t(COUNTRIES_INFORMATION[countryInformation].name)}
                </S.StyledName>
                <span>
                  {COUNTRIES_INFORMATION[countryInformation].phoneCode}
                </span>
              </S.StyledCountryItem>
            );
          })}
        </S.StyledCountriesList>
      </S.StyledInputWrapper>

      <S.StyledErrorWrapper>
        {/* <AppDescriptionComponents
          infoStatus={error ? "error" : undefined}
          error={error}
          description={description}
        /> */}
      </S.StyledErrorWrapper>
    </S.StyledPhoneInputWrapper>
  );
});

PhoneInput.displayName = "PhoneInput";
