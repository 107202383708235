import { memo, ReactNode } from "react";
import { Navigation } from "../Navigation";
import styled from "styled-components";
import { Footer } from "../Footer";
import Header from "../Header/Header";
import { media } from "../../ui/config";

interface LayoutProps {
  children: ReactNode;
}

export const Layout = memo((props: LayoutProps) => {
  const { children } = props;
  return (
    <StyledLayout>
      <Navigation />
      <StyledContent>
        <Header /> {children}
      </StyledContent>
      <Footer />
    </StyledLayout>
  );
});

const StyledLayout = styled.div`
  display: flex;
  padding-top: 120px;
  flex-direction: column;

  ${media.mobileL`
    padding-top: 100px;
  `}
`;
const StyledContent = styled.div`
  min-height: 100svh;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  width: 100%;
  padding: 0 50px;
  margin: 0 auto;
  ${media.mobileL`
        padding: 0 20px;
    `}

  .our-routes {
    ${media.mobileL`
        
        font-size: 18px !important;
      `}
  }
  .our-routes-btn {
    border: none;
    outline: none;
    background: none;
    min-height: 40px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    background: rgb(200 219 251);
    color: rgb(11 115 254);
    border-radius: 999px;
    height: 30px;
    padding: 5px 20px;
    transition: all 0.3s;

    &:hover {
      background: rgb(163 197 255);
    }
  }
`;
