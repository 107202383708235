import flagArmenia from "../../images/am.png";
import flagRussia from "../../images/ru.png";

export interface ICountryInformation {
  name: string;
  flag: string;
  placeholder: string;
  maxLength: number;
  phoneCode: string;
  shortPhoneCode: string;
}

export const COUNTRIES_INFORMATION = {
  am: {
    name: "armenia",
    flag: flagArmenia,
    placeholder: "00 00-00-00",
    maxLength: 11,
    phoneCode: "+374",
    shortPhoneCode: "0",
  },
  ru: {
    name: "russia",
    flag: flagRussia,
    placeholder: "000 000-00-00",
    maxLength: 11,
    phoneCode: "+7",
    shortPhoneCode: "8",
  },
};
