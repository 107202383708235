import styled from "styled-components";
import { media } from "../../ui/config";

export const StyledCartItem = styled.div`
  padding: 20px;
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;
export const StyledTitle = styled.span`
  font-weight: 600;
  font-size: 20px;

  ${media.mobileL`
    font-size: 24px;
    font-weight: 800;
  `}
`;
export const RouteImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 14px;
  margin-top: 5px;
`;
export const StyledPricesRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const StyledButtons = styled(StyledPricesRow)`
  flex-direction: column;
  margin-top: 10px;

  ${media.mobileL`
    margin: 0;
    `}

  > :first-child {
    &:hover {
      background: rgb(0 70 218) !important;
    }
  }
  > :last-child {
    &:hover {
      background: rgba(0, 70, 218, 0.1) !important;
    }
  }
`;
