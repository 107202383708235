import { memo, useCallback, useEffect, useState } from "react";
import { useMount } from "react-use";
import { useFirebase } from "../../hooks/useFirebase";
import { useParams } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { firebaseDb } from "../../firebaseConfig";
import { TChat } from "../../components/Callback/types";
import styled from "styled-components";
import { MessagesList } from "../../components/Callback/MessagesList";
import { useApp } from "../../contexts";

export const ChatItem = memo(() => {
  const [isAdmin, setIsAdmin] = useState(true);
  const [loading, setLoading] = useState(false);
  const { name: chatId } = useParams<{ name: string }>();
  const [activeChat, setActiveChat] = useState<TChat | null>(null);
  const [inputValue, setInputValue] = useState("");

  const { getData } = useFirebase();

  const check = useCallback(async () => {
    try {
      setLoading(true);
      const login = prompt("Admin login");
      const password = prompt("Admin password");

      const res = (await getData("admin", "data")) as {
        login: string;
        password: string;
      };
      if (res.login !== login || res.password !== password) {
        alert("Wrong login or pass");
      } else {
        setIsAdmin(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [getData]);

  useMount(() => {
    check();
  });

  const followUserChat = useCallback(() => {
    if (!chatId) return;
    const docRef = doc(firebaseDb, "chat", chatId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const newUserChat = doc.data() as TChat;
        setActiveChat(newUserChat);
      } else {
        console.log("Документ не существует!");
      }
    });
    return () => unsubscribe();
  }, [chatId]);

  useEffect(() => {
    followUserChat();
  }, [followUserChat]);

  const { sendMessageFromOperator } = useApp();

  const handleClick = useCallback(async () => {
    try {
      if (!inputValue.trim() || !chatId) return;
      await sendMessageFromOperator({ chatId, message: inputValue.trim() });
      setInputValue("");
    } catch (error) {
      console.error(error);
    }
  }, [chatId, inputValue, sendMessageFromOperator]);

  if (loading || !activeChat)
    return (
      <div>
        <h1>LOADING...</h1>
      </div>
    );

  if (!isAdmin) return <div>You are is not admin</div>;

  return (
    <StyledWrapper>
      <StyledListWrapper>
        <MessagesList chat={activeChat} />
      </StyledListWrapper>
      <StyledInputs>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button onClick={handleClick}>submit</button>
      </StyledInputs>
    </StyledWrapper>
  );
});
const StyledWrapper = styled.div`
  width: 100vw;
  max-width: 350px;
  height: 80svh;
  margin: 0 auto;
  margin-top: 100px;
  border: 1px solid black;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const StyledListWrapper = styled.div`
  height: 100%;
  max-height: 85%;
`;
const StyledInputs = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  > input {
    width: 100%;
    height: 30px;
  }
  > button {
    width: 100%;
    height: 30px;
  }
`;
