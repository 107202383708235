export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  // Extract day, month, and year from the Date object
  const day = String(date.getDate()).padStart(2, "0");
  const month = date.getMonth(); // Months are zero-based
  const year = date.getFullYear();

  // Array of month names in Russian
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  // Format the date as "DD MMMM YYYY"
  const formattedDate = `${day} ${months[month]} ${year}`;
  return formattedDate;
};
