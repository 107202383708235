import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { MainPage } from "./pages/MainPage";
import { useApp } from "./contexts";
import { useMount } from "react-use";
import { InitLoading } from "./components/InitLoading";
import { RoutePage } from "./pages/RoutePage";
import { ReservationPage } from "./pages/ReservationPage";
import "@babel/polyfill";
import { Buffer } from "buffer";
import { Callback } from "./components/Callback";
import { useEffect } from "react";
import { ChatItem } from "./pages/ChatItem";
window.Buffer = Buffer;

function App() {
  const { getPrices, followUserChat } = useApp();

  useMount(getPrices);

  useEffect(() => {
    followUserChat();
  }, [followUserChat]);

  return (
    <div className="App">
      <InitLoading />
      <Callback />
      <Routes>
        <Route path="/" element={<Layout children={<MainPage />} />} />
        <Route
          path="/reservation"
          element={<Layout children={<ReservationPage />} />}
        />
        <Route
          path="/route/:name"
          element={<Layout children={<RoutePage />} />}
        />
        <Route
          path="/admin/chat"
          element={<Layout children={<div>admin chat </div>} />}
        />
        <Route path="/admin/chat/:name" element={<ChatItem />} />
      </Routes>
    </div>
  );
}

export default App;
