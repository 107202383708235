import styled from "styled-components";
import { media } from "../config";

export const StyledDropdown = styled.div`
  position: relative;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  width: 300px;
  ${media.mobileL`
        width: calc(100vw - 40px);
    `}
`;
export const StyledHeaderText = styled.span`
  padding-bottom: 10px;
  color: white;
  font-size: 25px;
  font-weight: 600;
  margin: 0 auto;
  ${media.mobileL`
        font-size: 20px;
        margin: unset !important;
    `}
`;
export const StyledDropdownHeader = styled.div<{
  open: boolean;
  haveFlag: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${(p) => (p.haveFlag ? "0 8px" : "0 15px")};
  border-radius: ${(props) => `${props.open ? "10px 10px 0 0" : "10px"}`};
  /* transition: all .3s; */
  position: relative;
  z-index: 10;
  width: 100%;
  height: 50px;
  background: ${() => `white`};
  box-shadow: ${() => `0 2px 6px 0 rgba(0,0,0,0.15);`};
  > svg {
    transition: all 0.3s;
    transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
  }
  border-bottom: ${(props) =>
    props.open ? "1px solid rgba(0,0,0,0.15)" : "1px solid white"};

  ${media.mobileL`
        width: 100%;
    `}
  cursor: pointer;
`;
export const StyledDropdownBody = styled.ul<{ open: boolean }>`
  list-style: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  box-shadow: ${(props) => `0 2px 6px 0 rgba(0,0,0,0.15);`};
  z-index: 11;
  top: 50px;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 0 0px 10px 10px;
  ${media.mobileL`
        width: 100%;
    `}

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
  }
`;
export const StyledHeaderLabel = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => "#0656fe"};
`;
export const StyledPlaceholder = styled(StyledHeaderLabel)`
  opacity: 0.5;
  font-weight: 600;
`;
export const StyledBodyItem = styled.li<{ isPercentsCount: boolean }>`
  width: 100%;
  min-height: 50px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 15px;
  display: flex;
  align-items: center;

  > .percent {
    color: #e98155;
    margin-left: 5px;
    font-weight: 600;
  }
  > :first-child {
    font-weight: ${(p) => p.isPercentsCount && "700"};
  }
  cursor: pointer;
  :hover {
    background: ${(props) => "#E5F0EC"};
  }

  :last-child {
    border-radius: 0 0 10px 10px;
  }

  :last-child {
    border: none;
    border-radius: 0 0 10px 10px;
  }
  color: ${(props) => "#0656fe"};
  font-weight: 500;
`;
export const StyledFlag = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;
export const StyledContent = styled.div`
  position: relative;
  width: 100%;
`;
export const StyledHeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
