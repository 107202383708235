import { memo } from "react";
import styled from "styled-components";

import car3 from "../../images/cars/car3.webp";
import car4 from "../../images/cars/car4.webp";
import car5 from "../../images/cars/car5.webp";
import car6 from "../../images/cars/car6.webp";
import car7 from "../../images/cars/car7.webp";
import car1 from "../../images/cars/car1.webp";
import useLocal from "../../hooks/useLocal";
import { media } from "../../ui/config";

const cars = [car1, car3, car4, car5, car6, car7];

export const OurCars = memo(() => {
  const { t } = useLocal();
  return (
    <StyledPhotosWrapper>
      <span style={{ fontSize: 30, fontWeight: 900 }}>{t("our_cars")}</span>
      <div style={{ height: 200 }}></div>
      <div className="scrollable-block" style={{ height: 200, width: "100%" }}>
        {[...cars].map((src) => (
          <img src={src} alt="car photo" key={src} />
        ))}
      </div>
    </StyledPhotosWrapper>
  );
});

OurCars.displayName = "OurCarsf";

const StyledPhotosWrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  margin-bottom: 20px;

  ${media.mobileL`
    padding: 20px;
  `}

  .scrollable-block {
    width: calc(100% + 50px);
    left: 0;
    bottom: 20px;
    position: absolute;
    overflow-y: auto;
    display: flex;
    padding: 0 20px;
    &::-webkit-scrollbar {
      display: none;
    }

    img {
      height: 100%;
      object-fit: cover;

      :first-child {
        border-radius: 25px 0 0 25px;
      }
      :last-child {
        border-radius: 0 25px 25px 0;
      }
    }
  }
`;
