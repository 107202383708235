import styled from "styled-components";
import { media } from "../config";

export const StyledPhoneInputWrapper = styled.div<{ $width?: number }>`
  width: ${(p) => (p.$width ? `${p.$width}px` : "100%")};
  display: flex;
  flex-direction: column;
  width: 300px;
`;
export const StyledInputWrapper = styled.div`
  max-width: 300px;
  min-height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  ${media.mobileL`
    max-width: 100%;
  `}
`;
export const StyledPhoneInputHeader = styled.div<{
  $active?: boolean;
  $haveError: boolean;
}>`
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  min-height: 50px;

  padding-left: 8px;
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
`;
export const StyledSelectCountry = styled.div`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: rgb(6, 86, 254);
  > span {
    font-weight: 600;
  }
`;
export const StyledFlag = styled.img`
  max-height: 30px;
  min-height: 30px;
  max-width: 30px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;
export const StyledPhoneInput = styled.textarea`
  border: none;
  outline: none;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  background: none;
  width: 100%;
  color: black;
  z-index: 2;
  position: absolute;
  left: 0;
  padding: 0;
  color: rgb(6, 86, 254);
  ::placeholder {
    color: blue;
  }
  height: 100vh;
  padding-top: 14.5px;

  ${media.tabletL`
    padding-top: 13.5px;  
  `}
`;
export const StyledCountriesList = styled.div<{
  $isOpen?: boolean;
  $countryListDirection: "top" | "bottom";
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: ${(p) => p.$countryListDirection === "bottom" && "55px"};
  bottom: ${(p) => p.$countryListDirection === "top" && "55px"};
  max-height: ${(p) => (p.$isOpen ? "225px" : "0")};
  opacity: ${(p) => (p.$isOpen ? 1 : 0)};
  z-index: ${(p) => (p.$isOpen ? 10 : -10)};
  transition: all 0.2s;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  background: white;

  &::-webkit-scrollbar-track {
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;
export const StyledCountryItem = styled.div<{ $active: boolean }>`
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 45px;
  padding: 0;
  padding-left: 8px;
  cursor: pointer;
  background: ${(p) => (p.$active ? "#E5F0EC" : "none")};
  transition: all 0.25s;
  &:hover {
    background: #e5f0ec;
  }
`;
export const StyledFakeNumbersBlock = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  left: 0;
  font-size: 16px;
  font-weight: 600;
  color: gray;
  padding: 0;
`;
export const StyledNumberBlock = styled.div`
  position: relative;
  height: 48px;
  width: 100%;
  font-family: "Inter", sans-serif;
  overflow: hidden;
`;
export const StyledErrorWrapper = styled.div``;
export const StyledDescription = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: gray;
`;
export const StyledRemoveButton = styled.button`
  border: none;
  outline: none;
  background: gray;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  > svg path {
    fill: red;
  }
  margin-right: 10px;
`;
export const StyledName = styled.span`
  font-weight: 600;
`;
export const StyledLabel = styled.span`
  padding-bottom: 10px;
  font-size: 25px;
  font-weight: 600;
`;
