import React, { FC } from "react";
import {
  StyledDropdownHeader,
  StyledFlag,
  StyledHeaderLabel,
  StyledHeaderRow,
  StyledPlaceholder,
} from "../styles";
import { BiChevronDown } from "react-icons/bi";
import { TDropdownOption } from "../Dropdown";
import ruFlag from "../../../images/ru.png";
import amFlag from "../../../images/am.png";
import useLocal from "../../../hooks/useLocal";
interface HeaderProps {
  open: boolean;
  handler: () => void;
  label?: string;
  activeOption: TDropdownOption | null;
  flag?: "am" | "ru";
}
const Header: FC<HeaderProps> = ({
  open,
  handler,
  label,
  activeOption,
  flag,
}) => {
  const { t } = useLocal();
  return (
    <StyledDropdownHeader
      onClick={handler}
      open={open}
      haveFlag={Boolean(flag)}
    >
      <StyledHeaderRow>
        {flag ? <StyledFlag src={flag === "am" ? amFlag : ruFlag} /> : null}
        {activeOption ? (
          <StyledHeaderLabel>{t(activeOption.value)}</StyledHeaderLabel>
        ) : (
          <StyledPlaceholder>{t("select_value")}</StyledPlaceholder>
        )}
      </StyledHeaderRow>
      <BiChevronDown color="#0656fe" size={30} />
    </StyledDropdownHeader>
  );
};

export default Header;
