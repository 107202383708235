import styled from "styled-components";
import { media } from "../../ui/config";

export const StyledWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;

  ${media.mobile`
    zoom: 0.9;
  `}
`;
export const NewPrice = styled.span`
  font-size: 18px;
  text-transform: lowercase;
  display: flex;
  gap: 5px;
  font-weight: 600;

  > span {
    font-size: 20px;
    font-weight: 700;
    color: #fa742d;
  }
`;
export const OldPrice = styled.span`
    font-size: 14px;
    text-transform: lowercase;
    color: rgba(0,0,0,0.7);
    text-decoration: line-through;
    display: flex;
    gap: 5px;
`
