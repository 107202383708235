// import { setActiveCurrency, setActiveLanguage } from "@/redux/features/actions";
// import { RootState } from "@/redux/store";
// import { useDispatch, useSelector } from "react-redux";
import { useCallback, useState } from "react";
import {
  ICurrency,
  ILanguage,
  LOCAL_CURRENCIES,
  LOCAL_LANGUAGES,
} from "../interfaces";
import { translates } from "../translates";
import { useApp } from "../contexts";
import { useAppContext } from "../contexts/AppContext/useAppContext";

export const languages: ILanguage[] = [
  { id: 0, title: "Հայերեն", value: LOCAL_LANGUAGES.AM },
  { id: 1, title: "Русский", value: LOCAL_LANGUAGES.RU },
];

const currencies: ICurrency[] = [
  { id: 0, title: LOCAL_CURRENCIES.amd, value: LOCAL_CURRENCIES.amd },
  { id: 1, title: LOCAL_CURRENCIES.rub, value: LOCAL_CURRENCIES.rub },
];

export default function useLocal() {
  //   const { language, currency } = useSelector(
  //     (store: RootState) => store.reducer
  //   );
  const { activeLang, changeLang } = useApp();
  //   const dispatch = useDispatch();
  // const [activeLang, setActiveLang] = useState(languages[0]);
  // const changeLang = useCallback(
  //   () => setActiveLang(languages[activeLang.id === 0 ? 1 : 0]),
  //   [activeLang.id]
  // );

  // const { activeLang, changeLang } = useApp();

  const switchActiveLanguage = useCallback(() => {
    // const activeIndex = language?.id === languages[0].id ? 1 : 0;
    // dispatch(setActiveLanguage(languages[activeIndex]));
    // localStorage.setItem("rusarm-lang", String(activeIndex));
  }, []);

  const switchActiveCurrency = useCallback(() => {
    // currency?.id === currencies[0].id
    //   ? dispatch(setActiveCurrency(currencies[1]))
    //   : dispatch(setActiveCurrency(currencies[0]));
  }, []);

  const changeActiveLanguageAndCurrencyOnRendering = useCallback(() => {
    // dispatch(setActiveCurrency(currencies[1]));
    // dispatch(
    //   setActiveLanguage(
    //     languages[
    //       Number(activeLang) >= 0 && Number(activeLang) <= 1
    //         ? Number(activeLang)
    //         : 0
    //     ]
    //   )
    // );
  }, []);

  const t = useCallback(
    (key: string) => {
      const label = translates.find((label) => label.key === key);
      if (label) {
        if (activeLang?.value === LOCAL_LANGUAGES.AM) return label?.label_am;
        else return label?.label_ru;
      } else {
        return key;
      }
    },
    [activeLang]
  );

  return {
    language: activeLang,
    languages,
    switchActiveLanguage: changeLang,
    currencies,
    switchActiveCurrency,
    changeActiveLanguageAndCurrencyOnRendering,
    changeLang,
    t,
  };
}
