import { memo } from "react";
import { NewPrice, OldPrice, StyledWrapper } from "./styles";
import useLocal from "../../hooks/useLocal";
import { LOCAL_CURRENCIES } from "../../interfaces";
import { numberWithSpaces } from "../../utils";

interface OldAndNewPriceProps {
  currency: LOCAL_CURRENCIES;
  price: number;
  oldPrice?: number;
}

export const OldAndNewPrice = memo((props: OldAndNewPriceProps) => {
  const { currency, oldPrice, price } = props;
  const { t } = useLocal();

  return (
    <StyledWrapper>
      <NewPrice>
        <span>{numberWithSpaces(price, ".")}</span> {t(currency)}
      </NewPrice>
      {oldPrice && (
        <OldPrice>
          <span>{numberWithSpaces(oldPrice, ".")}</span> {t(currency)}
        </OldPrice>
      )}
    </StyledWrapper>
  );
});
OldAndNewPrice.displayName = "OldAndNewPrice";
