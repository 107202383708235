import React from "react";
import logo from "../../images/logo_3.png";
import logoNew from "../../images/newlogo.png";
import logoNewRu from "../../images/newlogoru.png";

import { useNavigate, useLocation } from "react-router-dom";

import { StyledLogotype, StyledSmallLogotype } from "../Navigation/styles";
import useLocal from "../../hooks/useLocal";

const LogoType = ({ type = "primary" }: { type?: "primary" | "secondary" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useLocal();
  const click = () => {
    if (location.pathname === "/") return;
    navigate("/");
  };
  return (
    <>
      <StyledLogotype
        onClick={click}
        src={
          type === "primary"
            ? logo
            : language?.value === "am"
            ? logoNew
            : logoNewRu
        }
      />
      <StyledSmallLogotype
        onClick={click}
        src={
          type === "primary"
            ? logo
            : language?.value === "am"
            ? logoNew
            : logoNewRu
        }
      />
    </>
  );
};

export default LogoType;
