import React, { memo, useCallback, useState } from "react";
import {
  StyledNavigation,
  StyledNavigationContent,
  StyledNavigationMore,
  StyledNavigationMoreContent,
  StyledNavigationTop,
  StyledRightContent,
} from "./styles";
import Container from "../Container";
import Button from "../Button";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import LogoType from "../Logotype";
import { Switchers } from "../Switchers";
import useLocal from "../../hooks/useLocal";
import { CgDetailsMore } from "react-icons/cg";

import { MdOutlineLocalPhone } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export const Navigation = memo(() => {
  const { t } = useLocal();

  const [moreIsOpen, setMoreIsOpen] = useState(false);
  const navigate = useNavigate();
  const onBron = useCallback(() => navigate("/reservation"), []);

  return (
    <StyledNavigation>
      <StyledNavigationTop>
        <Container>
          <div
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <span
              style={{
                color: "white",
                fontWeight: 600,
                letterSpacing: 0.8,
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <MdOutlineLocalPhone />
              {/* <span style={{ color: "white", fontSize: 14, fontWeight: 400 }}>
                {t("phone") + ":"}{" "}
              </span>{" "} */}
              <span>
                <a className="phone-item" href="tel:+37493604808">
                  +374 93 604-808
                </a>
                <span style={{ margin: "0 5px" }}>|</span>
                <a className="phone-item" href="tel:+79040909959">
                  +7 904 090-99-59
                </a>
              </span>
            </span>
            <div id="insta-facebook" style={{ display: "flex", gap: 2 }}>
              <a
                href="https://www.facebook.com/profile.php?id=61561598216291"
                target="_blank"
              >
                <Button
                  content={<FaFacebookF />}
                  theme="primary"
                  customStyles={{
                    fontSize: 14,
                    fontWeight: 600,
                    minWidth: 35,
                    width: 35,
                    minHeight: 35,
                    height: 35,
                  }}
                />
              </a>
              <a
                href="https://www.instagram.com/rusarm.transits/"
                target="_blank"
              >
                <Button
                  content={<FaInstagram />}
                  theme="primary"
                  customStyles={{
                    fontSize: 16,
                    fontWeight: 600,
                    minWidth: 35,
                    width: 35,
                    minHeight: 35,
                    height: 35,
                  }}
                />
              </a>
              <div style={{ marginLeft: 3 }}>
                <Switchers />
              </div>
            </div>
          </div>
        </Container>
      </StyledNavigationTop>
      <Container>
        <StyledNavigationContent>
          <LogoType type={"secondary"} />
          <StyledRightContent>
            <button className="bron-header" onClick={onBron}>
              {t("bron_online")}
            </button>
          </StyledRightContent>
          <StyledNavigationMore onClick={() => setMoreIsOpen(!moreIsOpen)}>
            {moreIsOpen ? (
              <IoClose size={30} color="white" />
            ) : (
              <CgDetailsMore size={30} color="white" />
            )}
          </StyledNavigationMore>
          <StyledNavigationMoreContent
            $isOpen={moreIsOpen}
            onClick={() => setMoreIsOpen(false)}
          >
            <div
              id="insta-facebook"
              style={{ display: "flex", gap: 2, zoom: 1.2 }}
            >
              <a
                href="https://www.facebook.com/profile.php?id=61561598216291"
                target="_blank"
              >
                <Button
                  content={<FaFacebookF />}
                  theme="primary"
                  customStyles={{
                    fontSize: 14,
                    fontWeight: 600,
                    minWidth: 35,
                    width: 35,
                    minHeight: 35,
                    height: 35,
                  }}
                />
              </a>
              <a
                href="https://www.instagram.com/rusarm.transits/"
                target="_blank"
              >
                <Button
                  content={<FaInstagram />}
                  theme="primary"
                  customStyles={{
                    fontSize: 16,
                    fontWeight: 600,
                    minWidth: 35,
                    width: 35,
                    minHeight: 35,
                    height: 35,
                  }}
                />
              </a>
              <div style={{ marginLeft: 3 }}>
                <Switchers />
              </div>
            </div>
            <button
              className="bron-header"
              onClick={() => {
                setMoreIsOpen(false);
                onBron();
              }}
            >
              {t("bron_online")}
            </button>
          </StyledNavigationMoreContent>
        </StyledNavigationContent>
      </Container>
    </StyledNavigation>
  );
});
