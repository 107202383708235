import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Ваши данные конфигурации Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDV2bNQQLWRdS7CAYyE-Ef055ZFYDI-zeg",
  authDomain: "rusarm-x.firebaseapp.com",
  projectId: "rusarm-x",
  storageBucket: "rusarm-x.appspot.com",
  messagingSenderId: "1017179174394",
  appId: "1:1017179174394:web:4e8649b52cedd57f3be043",
};

// Инициализация Firebase
export const app = initializeApp(firebaseConfig);
export const firebaseDb = getFirestore(app);
export const auth = getAuth(app);
