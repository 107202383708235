import { memo } from "react";
import styled from "styled-components";
import { media } from "../config";
import useLocal from "../../hooks/useLocal";

export type TSelectorItem = {
  value: number;
  label: string;
};

interface SelectorProps {
  options: TSelectorItem[];
  active: number;
  onChange: (value: number) => void;
  label?: string;
  headerColor?: string;
  isCheckout?: boolean;
}

export const Selector = memo((props: SelectorProps) => {
  const { active, onChange, options, headerColor, isCheckout } = props;
  const { t } = useLocal();
  return (
    <SelectorWrapper>
      <StyledHeaderText
        style={{
          color: headerColor || "white",
          margin: headerColor === "black" ? "unset" : "0 auto",
          textAlign: isCheckout ? "start" : "center",
        }}
      >
        {t("select_direction")}:
      </StyledHeaderText>
      <StyledSelector>
        {options.map((i) => (
          <Option
            onClick={() => i.value !== active && onChange(i.value)}
            active={i.value === active}
            key={i.value}
          >
            {i.label}
          </Option>
        ))}
        <StyledScale active={active} />
      </StyledSelector>
    </SelectorWrapper>
  );
});

Selector.displayName = "Selector";

const StyledSelector = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  gap: 20px;
  padding: 0 20px;
  position: relative;

  * {
    transition: all 0.3s;
  }
`;
const Option = styled.div<{ active: boolean }>`
  width: 50%;
  position: relative;
  z-index: 2;
  cursor: pointer;
  color: ${(p) => (p.active ? "white" : "rgba(0,0,0,0.7)")};
  text-align: center;
  font-weight: 600;
  height: 90%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${(p) => !p.active && "rgba(0,0,0,0.05)"};
  }
  ${media.mobileL`
    font-size: 14px;
  `}
`;
const StyledScale = styled.div<{ active: number }>`
  width: calc(50% - 3px);
  background: rgb(6, 86, 254);
  position: absolute;
  left: ${(p) => (p.active === 0 ? "3px" : "calc(100% - (50% - 3px) - 3px)")};
  height: 90%;
  border-radius: 8px;
`;
const StyledHeaderText = styled.span`
  color: white;
  font-size: 25px;
  font-weight: 600;
  margin: 0 auto;

  ${media.mobileL`
    font-size: 20px;  
    margin: unset !important;
    text-align: start !important;
  `}
`;
const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
