export function generateUniqueToken() {
  const timestamp = Date.now().toString(36);
  const randomValue = Math.random().toString(36).substring(2);
  return `${timestamp}-${randomValue}`;
}

export function getTimeNowForMessage() {
  const date = new Date();

  const day = date.getDate();
  const month = date.toLocaleString("en-GB", { month: "long" });
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Format the date as "21 August, 11:41"
  const formattedDate = `${day} ${month}, ${hours}:${minutes}`;
  return formattedDate;
}
