import { memo } from "react";
import { useApp } from "../../contexts";
import styled, { keyframes } from "styled-components";

export const InitLoading = memo(() => {
  const { isInitLoading } = useApp();

  if (!isInitLoading) return <></>;

  return (
    <StyledInitLoading $isInitLoading={isInitLoading}>
      <Loader></Loader>
    </StyledInitLoading>
  );
});

InitLoading.displayName = "InitLoading";

const l3 = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const StyledInitLoading = styled.div<{ $isInitLoading: boolean }>`
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(239 241 244);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
`;
const Loader = styled.div`
  width: 100px;
  padding: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: rgb(0, 70, 218);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: ${l3} 1s infinite linear;
`;
