import {
  memo,
  FC,
  useEffect,
  useCallback,
  useMemo,
  useState,
  ChangeEvent,
} from "react";

import OTPInput from "react-otp-input";

import {
  StyledErrorMessage,
  StyledErrorWrapper,
  StyledOTPCode,
  StyledOTPInput,
} from "./styles";
import { MdError } from "react-icons/md";
import useLocal from "../../hooks/useLocal";
import { useDebounceSearch } from "../../hooks/useDebounceSearch";

interface OTPCodeProps {
  code: string;
  onChange: (code: string) => void;
  inputsCount: number;
  shouldAutoFocus?: boolean;
  error?: boolean;
  action?: (code: string) => void;
}

export const OtpCode: FC<OTPCodeProps> = memo(
  ({ code, inputsCount, onChange, shouldAutoFocus = true, error, action }) => {
    const [pastedValue, setPastedValue] = useState("");
    const { t } = useLocal();

    const debouncedCode = useDebounceSearch(code, 200);

    const handler = useCallback(async () => {
      if (debouncedCode.length === inputsCount) action?.(debouncedCode);
    }, [debouncedCode]);

    useEffect(() => {
      if (!action) return;
      handler();
    }, [debouncedCode, action]);

    const hasError = useMemo(
      () => inputsCount === code.length && error,
      [error, code, inputsCount]
    );

    const autoFillHandler = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length === inputsCount) {
          onChange(value);
          setPastedValue("");
          return;
        }
        if (pastedValue.length === inputsCount - 1) {
          const filledValue = pastedValue + value;
          onChange(filledValue);
          setPastedValue("");
          return;
        }
        setPastedValue((prev) => prev + value);
      },
      [onChange, pastedValue, inputsCount]
    );

    return (
      <StyledOTPCode>
        <OTPInput
          onChange={onChange}
          value={code}
          numInputs={inputsCount}
          renderInput={(props) => (
            <StyledOTPInput
              {...props}
              inputMode="numeric"
              onInput={autoFillHandler}
              placeholder="0"
            />
          )}
          inputType="number"
          shouldAutoFocus={shouldAutoFocus}
          containerStyle={{ justifyContent: "space-between", display: "flex" }}
        />
        <StyledErrorWrapper>
          {hasError && (
            <StyledErrorMessage>
              <MdError color="red" size={20} />
              <span style={{ color: "red", fontWeight: 600 }}>
                {t("wrong_code")}
              </span>
            </StyledErrorMessage>
          )}
        </StyledErrorWrapper>
      </StyledOTPCode>
    );
  }
);

OtpCode.displayName = "OtpCode";
