import { motion } from "framer-motion";
import styled from "styled-components";
import { media } from "../config";

export const ModalOverlay = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);

  ${media.mobileL`
      align-items: flex-end;
  `}
`;
export const ModalContent = styled(motion.div)`
  width: 40vw;
  min-height: 200px;
  background: white;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;

  ${media.tabletL`
    width: 96vw;
  `}

  ${media.mobileL`
    width: 100vw;
    height: fit-content;
    padding-bottom: 30px;
  border-radius: 16px 16px 0 0;
  `}
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  gap: 10px;
`;
export const Body = styled.div`
  padding: 20px;
`;
