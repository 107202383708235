import React, { FC, useMemo, useRef, useState } from "react";
import { StyledContent, StyledDropdown, StyledHeaderText } from "./styles";
import Header from "./components/Header";
import Body from "./components/Body";
import useLocal from "../../hooks/useLocal";
import { useOnClickOutside } from "../../utils";

export type TDropdownOption = {
  value: string;
};

interface DropdownProps {
  label?: string;
  active: string | null; // is value active option
  options: TDropdownOption[];
  onChange: (option: TDropdownOption) => void;
  flag?: "am" | "ru";
  isCheckout?: boolean;
  isPercentsCount?: boolean;
}

const Dropdown: FC<DropdownProps> = (props) => {
  const {
    label,
    onChange,
    options,
    active,
    flag,
    isCheckout = false,
    isPercentsCount = false,
  } = props;
  const { t } = useLocal();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const activeOption = useMemo(
    () => options.find(({ value }) => value === active) || null,
    [active, options]
  );

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside({
    handler: () => setDropdownOpen(false),
    ref: dropdownRef,
  });

  return (
    <StyledDropdown>
      {label ? (
        <StyledHeaderText
          style={{
            color: isCheckout ? "black" : "white",
            margin: isCheckout ? "unset" : "0 auto",
          }}
        >
          {t(label)}:
        </StyledHeaderText>
      ) : null}
      <StyledContent ref={dropdownRef}>
        <Header
          open={dropdownOpen}
          handler={() => setDropdownOpen((prev) => !prev)}
          activeOption={activeOption}
          flag={flag}
        />
        {dropdownOpen ? (
          <Body
            setDropdownOpen={setDropdownOpen}
            open={dropdownOpen}
            onChange={onChange}
            options={options}
            isPercentsCount={isPercentsCount}
          />
        ) : null}
      </StyledContent>
    </StyledDropdown>
  );
};

export default Dropdown;
