import { FC, ReactNode } from "react";
import styled, { CSSProperties, keyframes } from "styled-components";
import { media } from "../../ui/config";

interface ButtonProps {
  content: ReactNode | string;
  theme?: "primary";
  onClick?: () => void;
  customStyles?: CSSProperties;
  isLoading?: boolean;
}
const Button: FC<ButtonProps> = (props) => {
  const { content, customStyles, onClick, isLoading = false } = props;

  return (
    <StyledButton style={customStyles} onClick={onClick} theme={"primary"}>
      {isLoading ? <Spinner /> : content}
    </StyledButton>
  );
};

export default Button;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 2px solid transparent;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;
export const StyledButton = styled.button<{ theme: 'primary' }>`
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    /* width: 35px; */
    padding: 5px;
    height: 35px;
    max-height: 35px;
    min-width: 35px;
    transition: all .2s;
    background: none;
    color: white;
    border-radius: 8px;
    :hover {
        background: hsla(0,0%,100%,.1);

        ${media.tabletL`
            background: auto;
        `}
    }
    :focus {
        ${media.tabletL`
            background: hsla(0,0%,100%,.1);
        `}
    }

`