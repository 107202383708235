import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { TelegramClient } from "messaging-api-telegram";

import useLocal from "../../hooks/useLocal";
import {
  Label,
  PriceContent,
  StyledMobileBlock,
  StyledReservationContent,
  StyledRow,
  StyledStep1,
} from "./styles";
import { Selector } from "../../ui/Selector";
import { useCity } from "../../hooks/useCity";
import { formatDate, transformPhoneNumber } from "../../utils";
import { COUNTRIES_INFORMATION } from "../../ui/PhoneInput/constants";
import { useApp } from "../../contexts";
import Dropdown from "../../ui/Dropdown/Dropdown";
import { FormDatePicker } from "../../ui/DatePicker";
import { PhoneInput } from "../../ui/PhoneInput";
import { OldAndNewPrice } from "../../components/OldAndNewPrice";
import { LOCAL_CURRENCIES } from "../../interfaces";
import Button from "../../components/Button";
import { FaCircleInfo } from "react-icons/fa6";
import { Modal } from "../../ui/Modal";
import { Verification } from "./Verification";
import { FaInfoCircle } from "react-icons/fa";
import { Helmet } from "react-helmet-async";
import success from "../../images/success.png";

const isCheckout = true;
export interface Step1Values {
  position: 0 | 1;
  from: string | null;
  to: string | null;
  tickets: number;
  date: Date | null;
  phone: string;
}
const STEP_1_INITIAL_VALUES: Step1Values = {
  position: 0,
  from: "",
  to: "",
  tickets: 1,
  date: null,
  phone: "",
};

export const ReservationPage = memo(() => {
  const { t } = useLocal();
  const [step1Values, setStep1Values] = useState<Step1Values>(
    STEP_1_INITIAL_VALUES
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sendedModalIsOpen, setSendedModalIsOpen] = useState(false);
  const { activeReservation, findRoute } = useApp();

  useEffect(() => {
    if (!activeReservation) return;
    const activeRoute = findRoute(activeReservation);
    if (!activeRoute) return;
    const { from, to } = activeRoute;
    setStep1Values((prev) => ({ ...prev, from, to }));
  }, [activeReservation, findRoute]);

  const [activePhoneCountry, setActivePhoneCounty] = useState("am");
  const activeCountry = useMemo(
    () =>
      COUNTRIES_INFORMATION[
        activePhoneCountry as keyof typeof COUNTRIES_INFORMATION
      ],
    [activePhoneCountry]
  );

  const phoneIsValid = useMemo(() => {
    const length =
      COUNTRIES_INFORMATION[
        activePhoneCountry as keyof typeof COUNTRIES_INFORMATION
      ].maxLength;
    return length === step1Values.phone.length;
  }, [activePhoneCountry, step1Values.phone.length]);

  const { cities_am, cities_ru } = useCity();

  const client = new TelegramClient({
    accessToken: "7290784093:AAHh9bmVXbnD7oFbsF_4dOTSCeUmohJRMoA",
  });

  const sendTelegramMessage = useCallback(
    async (isVerified: boolean) => {
      if (!step1Values.from || !step1Values.to) return;
      try {
        const value = `${t(step1Values.from)} - ${t(step1Values.to)}, ${
          step1Values.tickets
        } билет, ${
          step1Values.date ? formatDate(String(step1Values.date)) : ""
        }, +${transformPhoneNumber(
          step1Values.phone,
          activeCountry.placeholder
        )} (${isVerified ? "прошел проверку" : "не прошел проверку"}) `;
        // await client.sendMessage("-4285204537", value);
        setModalIsOpen(false);
        // setTimeout(() => {
        //   setSendedModalIsOpen(true);
        // }, 100);
      } catch (error) {
        console.error(error);
      }
    },
    [
      activeCountry.placeholder,
      client,
      step1Values.date,
      step1Values.from,
      step1Values.phone,
      step1Values.tickets,
      step1Values.to,
      t,
    ]
  );
  const citiesAm = useMemo(
    () => cities_am.map(({ title }) => ({ value: title })),
    [cities_am]
  );
  const citiesRu = useMemo(
    () => cities_ru.map(({ title }) => ({ value: title })),
    [cities_ru]
  );

  const firstDropdown = useMemo(
    () => (step1Values.position === 0 ? "am" : "ru"),
    [step1Values]
  );

  const activeRoute = useMemo(
    () =>
      !step1Values.from || !step1Values.to
        ? null
        : findRoute(`${step1Values.from}-${step1Values.to}-avtobus`),
    [findRoute, step1Values.from, step1Values.to]
  );

  const onVerifySuccess = useCallback(() => {
    setModalIsOpen(false);
    setTimeout(() => {
      setSendedModalIsOpen(true);
    }, 100);
    sendTelegramMessage(true);
  }, [sendTelegramMessage]);

  const buttonClick = useCallback(() => {
    step1Values.date && phoneIsValid && setModalIsOpen(true);
  }, [step1Values, phoneIsValid]);

  return (
    <>
      <Helmet>
        <title>{t("rusarm_title")}</title>
        <meta name="description" content={"description"} />
        <meta property="og:title" content={"title"} />
        <meta property="og:description" content={"description"} />
        {/* Add other meta tags as needed */}
      </Helmet>
      <StyledReservationContent>
        <h1>{t("bron_billet")}</h1>
        <>
          <StyledStep1 isCheckout={isCheckout}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                width: "60%",
              }}
            >
              <Selector
                active={step1Values.position}
                onChange={(v) =>
                  setStep1Values((prev) => ({
                    ...prev,
                    position: v as 0 | 1,
                    from: prev.to,
                    to: prev.from,
                  }))
                }
                options={[
                  { label: t("from_armenia_to_russia"), value: 0 },
                  { label: t("from_russia_to_armenia"), value: 1 },
                ]}
                headerColor={isCheckout ? "black" : "white"}
                isCheckout={isCheckout}
              />
              <StyledRow isCheckout={isCheckout}>
                <Dropdown
                  active={step1Values.from}
                  onChange={({ value }) =>
                    setStep1Values((prev) => ({ ...prev, from: value }))
                  }
                  options={firstDropdown === "am" ? citiesAm : citiesRu}
                  label="from"
                  flag={firstDropdown === "am" ? "am" : "ru"}
                  isCheckout={isCheckout}
                />
                <Dropdown
                  active={step1Values.to}
                  onChange={({ value }) =>
                    setStep1Values((prev) => ({ ...prev, to: value }))
                  }
                  options={firstDropdown === "am" ? citiesRu : citiesAm}
                  flag={firstDropdown !== "am" ? "am" : "ru"}
                  label="to"
                  isCheckout={isCheckout}
                />
              </StyledRow>
              {isCheckout ? (
                <StyledRow isCheckout={isCheckout}>
                  <Dropdown
                    active={String(step1Values.tickets)}
                    onChange={({ value }) =>
                      setStep1Values((prev) => ({
                        ...prev,
                        tickets: Number(value),
                      }))
                    }
                    options={[
                      {
                        value: "1",
                      },
                      {
                        value: "2",
                      },
                      {
                        value: "3",
                      },
                      {
                        value: "4",
                      },
                      {
                        value: "5",
                      },
                      {
                        value: "6",
                      },
                      {
                        value: "7",
                      },
                    ]}
                    label="persons_count"
                    isCheckout={isCheckout}
                    isPercentsCount
                  />
                  <FormDatePicker
                    value={step1Values.date}
                    onChange={(v) =>
                      setStep1Values((prev) => ({ ...prev, date: v as Date }))
                    }
                  />
                </StyledRow>
              ) : null}
              {isCheckout ? (
                <StyledRow isCheckout={isCheckout}>
                  <PhoneInput
                    onChange={(v) =>
                      setStep1Values((prev) => ({ ...prev, phone: v }))
                    }
                    value={step1Values.phone}
                    label={t("phone_number") + ":"}
                    onCountryChange={setActivePhoneCounty}
                  />
                </StyledRow>
              ) : null}

              {isCheckout ? null : !step1Values.from ||
                !step1Values.to ||
                isCheckout ? (
                <Label>Выберите города и мы посчитаем цену</Label>
              ) : null}
            </div>
            {isCheckout && step1Values.from && step1Values.to && activeRoute ? (
              <PriceContent>
                <span
                  style={{
                    padding: "3px 10px",
                    borderRadius: "16px",
                    background: "rgb(8 94 253 / 30%)",
                    color: "#002669",
                    fontWeight: 600,
                    position: "absolute",
                    top: -10,
                    left: "50%",
                    transform: "translateX(-50%)",
                    fontSize: 14,
                    width: "fit-content",
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("bron_bez_oplati")}
                </span>
                <span style={{ fontSize: 30 }}>
                  <span style={{ fontWeight: 700 }}>{t(step1Values.from)}</span>{" "}
                  — <span style={{ fontWeight: 700 }}>{t(step1Values.to)}</span>{" "}
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    zoom: 1.3,
                    paddingTop: 10,
                  }}
                >
                  <OldAndNewPrice
                    currency={LOCAL_CURRENCIES.amd}
                    price={activeRoute.amd}
                  />
                  <OldAndNewPrice
                    currency={LOCAL_CURRENCIES.rub}
                    price={activeRoute.rub}
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  {!step1Values.date || !phoneIsValid ? (
                    <span
                      style={{
                        marginBottom: 5,
                        display: "block",
                        fontSize: 14,
                        fontWeight: 700,
                        color: "#fa742d",
                      }}
                    >
                      * {t("fill_all_fields")}{" "}
                    </span>
                  ) : null}
                  <Button
                    content={
                      <span
                        style={{
                          color: "white",
                          fontWeight: 600,
                          fontSize: 20,
                          textTransform: "capitalize",
                        }}
                      >
                        {t("online_bron_3")}
                      </span>
                    }
                    customStyles={{
                      width: "100%",
                      minHeight: 50,
                      background:
                        step1Values.date && phoneIsValid
                          ? "rgb(15 115 255)"
                          : "rgba(0,0,0,0.3)",
                      cursor:
                        step1Values.date && phoneIsValid
                          ? "pointer"
                          : "default",
                    }}
                    onClick={buttonClick}
                  />
                </div>
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    lineHeight: "100%",
                    marginTop: 10,
                  }}
                >
                  <span style={{ marginRight: 1, marginTop: 20 }}>
                    <FaInfoCircle />
                  </span>{" "}
                  {t("step_1_fixed_1")}{" "}
                </span>
              </PriceContent>
            ) : (
              <PriceContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    fontSize: 20,
                    textAlign: "center",
                    gap: 20,
                    fontWeight: 600,
                    border: "none",
                  }}
                >
                  <FaCircleInfo fontSize={50} />
                  <span>{t("select_route_for_calculate_price")}</span>
                </div>
              </PriceContent>
            )}
          </StyledStep1>
          <StyledMobileBlock />
          <Modal
            title={t("bron_billet")}
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
          >
            <Verification
              activeCountry={activeCountry}
              phone={step1Values.phone}
              onSuccess={onVerifySuccess}
              onErrorSend={() => sendTelegramMessage(false)}
              step1Values={step1Values}
            />
          </Modal>
          <Modal
            title={t("bron_billet")}
            isOpen={sendedModalIsOpen}
            onClose={() => setSendedModalIsOpen(false)}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <img
                src={success}
                alt="success"
                style={{ height: 70, width: 70, margin: "0 auto" }}
              />
              <span
                style={{ fontSize: 20, fontWeight: 600, textAlign: "center" }}
              >
                {t("bron_success_text")}
              </span>
            </div>
          </Modal>
        </>
      </StyledReservationContent>
    </>
  );
});
