import { memo, useCallback } from "react";
import styled from "styled-components";
import useLocal from "../../hooks/useLocal";
import Container from "../Container";
import { IoClose } from "react-icons/io5";
import { useApp } from "../../contexts";
import { media } from "../../ui/config";
import { OldAndNewPrice } from "../OldAndNewPrice";
import { LOCAL_CURRENCIES } from "../../interfaces";
import { useNavigate } from "react-router-dom";

interface SeeAllRoutesProps {
  onClose: () => void;
}

export const SeeAllRoutes = memo((props: SeeAllRoutesProps) => {
  const { onClose } = props;
  const { t } = useLocal();
  const { routes, setActiveReservation } = useApp();
  const navigate = useNavigate();

  const onBuy = useCallback(
    (name: string) => {
      setActiveReservation(name);
      navigate("/reservation");
    },
    [navigate, setActiveReservation]
  );

  return (
    <StyledSeeAllRoutes>
      <Container>
        <StyledContent>
          <StyledHeader>
            <span style={{ fontSize: 30, fontWeight: 700 }}>
              {t("all_routes")}
            </span>
            <div
              style={{
                width: 40,
                height: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 9999,
                background: "#c2d6ff",
                cursor: "pointer",
              }}
            >
              <IoClose
                onClick={onClose}
                size={30}
                color="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </StyledHeader>
          <StyledList>
            {routes.map((route) => (
              <StyledListItem
                onClick={() => onBuy(route.name)}
                key={route.name}
              >
                <StyledTitle>
                  {t(route.from)} — {t(route.to)}
                </StyledTitle>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <OldAndNewPrice
                    currency={LOCAL_CURRENCIES.amd}
                    price={route.amd}
                  />
                  /
                  <OldAndNewPrice
                    currency={LOCAL_CURRENCIES.rub}
                    price={route.rub}
                  />
                </div>
                <StyledBuyContent className="buy-content">
                  <button>{t("bron_online")}</button>
                </StyledBuyContent>
              </StyledListItem>
            ))}
          </StyledList>
          <div
            className="clasname-xz"
            style={{
              position: "fixed",
              bottom: 0,
              background: "rgb(239, 241, 244)",
              padding: "10x 0",
              borderTop: "1px solid rgba(0,0,0,0.05)",
              zIndex: 10,
            }}
          >
            <Container>
              <StyledDescriptionWrapper>
                {t("alternative_content")}
              </StyledDescriptionWrapper>
              <div
                className="xxxxxx"
                style={{
                  fontSize: 20,
                  marginTop: 20,
                  width: "fit-content",
                  margin: " 0 auto",
                }}
              >
                {t("soc_seti_1")} <span style={{ fontWeight: 900 }}>Viber</span>
                , <span style={{ fontWeight: 900 }}>Telegram</span>,{" "}
                <span style={{ fontWeight: 900 }}>WhatsApp</span>{" "}
                {t("soc_seti_2")} <br />
                <div
                  style={{
                    fontWeight: 900,
                    letterSpacing: 0.7,
                    width: "fit-content",
                    margin: " 0 auto",
                  }}
                >
                  +374 93 604-808<span style={{ fontWeight: 400 }}>,</span> +7
                  904 090-99-59
                </div>
              </div>
            </Container>
          </div>
        </StyledContent>
      </Container>
    </StyledSeeAllRoutes>
  );
});

export const StyledSeeAllRoutes = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100svh;
  background: rgb(239 241 244);
  overflow: auto;
  z-index: 10;
  padding-top: 130px;
  padding-bottom: 100px;

  ${media.mobileL`
    padding-top: 100px;
  `}

  .clasname-xz {
    > div {
      padding: 20px 0;
      max-width: 1180px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      ${media.mobileL`
        max-width: calc(100svw - 40px) !important;
        
        `}
    }
  }

  .xxxxxx {
    ${media.mobileL`
        zoom: 0.7;
        text-align: center;

    `}
  }
`;
export const StyledContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;

  > :first-child {
    ${media.mobileL`
        font-size: 20px !important; 
    `}
  }
`;
const StyledList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  max-height: 50vh;
  padding-bottom: 100px;
  overflow-y: auto;

  ${media.tabletL`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  `}
  ${media.mobileL`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  `}
`;
const StyledListItem = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  &:hover {
    border-radius: 10px 10px 0 0;
    .buy-content {
      opacity: 1;
    }
  }
`;
const StyledTitle = styled.span`
  font-weight: 800;
  font-size: 20px;
`;
const StyledBuyContent = styled.div`
  width: 100%;
  background: white;

  position: absolute;
  height: 50px;
  left: 0;
  bottom: -50px;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-top: 0;
  ${media.mobileL`
    bottom: -20px;
  `}
  button {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: none;
    outline: none;
    background: none;
    border: 1px solid rgb(0, 72, 221);

    color: rgb(0, 72, 221);
    background: rgba(0, 72, 221, 0.05);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: rgba(0, 72, 221, 0.1);
    }
  }
`;
export const StyledDescriptionWrapper = styled.div`
  bottom: 0;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 10px;

  ${media.mobileL`
  font-size: 16px;
  `}
`;
