import styled, { keyframes } from "styled-components";
import { media } from "../../ui/config";
import { ILanguage, LOCAL_LANGUAGES } from "../../interfaces";

const rotate360 = keyframes`
  40% {
    transform: scale(1);
  }
  /* 25% {
    transform: scale(1.03);
  } */
  50% {
    transform: scale(1.03);
  }
  /* 75% {
    transform: scale(1.03);
  } */
  60% {
    transform: 1;
  }
`;

export const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  user-select: none;
  cursor: pointer;
  background: radial-gradient(
    circle,
    rgba(6, 86, 254, 1) 15%,
    rgba(4, 72, 213, 1) 50%,
    rgb(0 72 221) 100%
  );
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  border-radius: 16px;
  position: relative;
  height: 200px;

  ${media.tabletL`
      padding: 20px 30px;
    `}
  ${media.mobileL`
      padding: 20px;
      flex-direction: column;
    `}


    .header-content {
    /* background: 'red'; */
    /* zoom: 2; */
    ${media.laptopS`
  zoom: 0.9;
`}
    ${media.tabletL`
  zoom: 0.7;
`}
${media.tablet`
  zoom: 0.6;
`}
${media.tabletS`
  zoom: 0.6;
`}
${media.mobileL`
  zoom: 0.5;
`}
${media.mobile`
  zoom: 0.4;
`}
  }
  .header-content-price {
    > span {
      /* transform: scale(1.03); */
      animation: ${rotate360} 2s ease-in-out infinite;
    }
    * {
      transition: all 0.5s;
    }
    /* &:hover {
      > span {
        transform: scale(1.03);
      }
    } */
    ${media.tablet`
  zoom: 0.5;
`}
    ${media.mobileL`
  zoom: 0.6;
`}
  }
`;
export const StyledH1 = styled.h1<{ $lang: LOCAL_LANGUAGES }>`
  color: white;
  font-size: 90px;
  font-weight: 900;
  line-height: 90%;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-family: ${(p) =>
    p.$lang === "ru" && '"Montserrat Alternates", sans-serif;'};
`;
export const StyledH2 = styled.h2<{ $lang: LOCAL_LANGUAGES }>`
  color: white;
  font-size: 45px;
  font-family: ${(p) =>
    p.$lang === "ru" && '"Montserrat Alternates", sans-serif;'};
  font-weight: 900;
  line-height: 90%;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;
export const StyledSalesImg = styled.img`
  position: absolute;
  width: 60%;
  height: 100%;
  right: 0;
  top: 0;
`;

export const StyledArrows = styled.img`
  position: absolute;
  left: 55%;
  transform: translateX(-50%);
  height: 200px;
  z-index: 2;
`;
