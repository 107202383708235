import React, { createContext, ReactNode, useContext } from "react";
import { useAppContext } from "./useAppContext";

export const AppInitializationContext = createContext(
  {} as ReturnType<typeof useAppContext>
);

export const AppInitializationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const value = useAppContext();
  return (
    <AppInitializationContext.Provider value={value}>
      {children}
    </AppInitializationContext.Provider>
  );
};
export const useApp = () => {
  const context = useContext(AppInitializationContext);
  if (!context) {
    throw new Error(
      "useAppInitialization must be used within an AppInitializationProvider"
    );
  }
  return context;
};
