import React, { FC } from "react";
import { StyledBodyItem, StyledDropdownBody } from "../styles";
import { TDropdownOption } from "../Dropdown";
import useLocal from "../../../hooks/useLocal";

interface BodyProps {
  open: boolean;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (option: TDropdownOption) => void;
  options: TDropdownOption[];
  flag?: "am" | "ru";
  isPercentsCount: boolean;
}

const Body: FC<BodyProps> = ({
  onChange,
  open,
  options,
  setDropdownOpen,
  isPercentsCount,
}) => {
  const { t } = useLocal();

  const changeActiveCity = React.useCallback(
    (item: TDropdownOption) => {
      onChange(item);
      setDropdownOpen(false);
    },
    [onChange, setDropdownOpen]
  );

  return (
    <StyledDropdownBody open={open}>
      {options.map((option) => (
        <StyledBodyItem
          onClick={() => changeActiveCity(option)}
          key={option.value}
          isPercentsCount={isPercentsCount}
        >
          <span>{t(option.value)}</span>
          {isPercentsCount && option.value === "7" ? (
            <span className="percent">(-7%)</span>
          ) : null}
        </StyledBodyItem>
      ))}
    </StyledDropdownBody>
  );
};

export default Body;
