import { AnimatePresence } from "framer-motion";
import { ReactNode, memo } from "react";
import { Body, Header, ModalContent, ModalOverlay } from "./styles";
import { IoCloseSharp } from "react-icons/io5";
import { useWindowSize } from "react-use";

const modal = {
  hidden: {
    y: "-100vH",
    x: "0",
  },
  visible: {
    y: "0px",
    x: "0px",
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 300,
      delay: 0.1,
    },
  },
  exit: {
    y: "-100vH",
    x: "0",
    transition: {
      duration: 0.3,
    },
  },
};

const modalMobile = {
  hidden: {
    y: "100vH",
    x: "0",
  },
  visible: {
    y: "0px",
    x: "0px",
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 300,
      delay: 0.1,
    },
  },
  exit: {
    y: "100vH",
    x: "0",
    transition: {
      duration: 0.3,
    },
  },
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
}

export const Modal = memo((props: ModalProps) => {
  const { isOpen, onClose, title, children } = props;

  const { width } = useWindowSize();

  return (
    <AnimatePresence initial={false} onExitComplete={() => null}>
      {isOpen && (
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { delay: 0.2 } }}
        >
          <ModalContent
            variants={width > 568 ? modal : modalMobile}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Header>
              <IoCloseSharp
                onClick={onClose}
                size={30}
                style={{ cursor: "pointer" }}
              />
              <span style={{ fontSize: 24, fontWeight: 600 }}>{title}</span>
            </Header>
            <Body>{children}</Body>
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
});

Modal.displayName = "Modal";
