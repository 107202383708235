import React, { ReactNode } from "react";
import styled from "styled-components";
import { media } from "../../ui/config";

const Container = ({ children }: { children: ReactNode }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

const StyledContainer = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 0 50px;
  margin: 0 auto;
  ${media.mobileL`
        padding: 0 20px;
    `}
`;

export default Container;
