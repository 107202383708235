import React from "react";
import styled from "styled-components";
import { MdLanguage } from "react-icons/md";
import { StyledNavigationButtonContent } from "../Navigation/styles";
import { HiOutlineLanguage } from "react-icons/hi2";

import Button from "../Button";
import useLocal from "../../hooks/useLocal";
export const Switchers = () => {
  const { language, switchActiveLanguage } = useLocal();
  return (
    <StyledSwitchers>
      <Button
        // customStyles={{ minWidth: 100, justifyContent: 'space-between' }}
        onClick={() => switchActiveLanguage()}
        theme="primary"
        content={
          <StyledNavigationButtonContent>
            <HiOutlineLanguage size={17} color="white" /> {language?.title}
          </StyledNavigationButtonContent>
        }
      />
      {/* <Button
                // customStyles={{ minWidth: 80, justifyContent: 'space-between' }}
                onClick={() => switchActiveCurrency()}
                theme='primary'
                content={
                    <StyledNavigationButtonContent>
                        <BsCurrencyExchange size={20} color='white' /> {t(`${currency?.title}`)}
                    </StyledNavigationButtonContent>
                }
            /> */}
    </StyledSwitchers>
  );
};

const StyledSwitchers = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
