export interface ILanguage {
  id: number;
  value: LOCAL_LANGUAGES;
  title: string;
}
export interface ITranslate {
  key: string;
  label_am: string;
  label_ru: string;
}
export enum LOCAL_LANGUAGES {
  AM = "am",
  RU = "ru",
}
export enum LOCAL_CURRENCIES {
  rub = "rub",
  amd = "amd",
}
export interface ICurrency {
  id: number;
  value: LOCAL_CURRENCIES;
  title: LOCAL_CURRENCIES;
}
export interface ICity {
  title: string;
  id: number;
}
export interface IRoute {
  name: string;
  from: string;
  to: string;
  amd: number;
  rub: number;
  img: string;
  keys: string;
  time: string;
  distance: string;
}
