import { memo } from "react";
import styled from "styled-components";
import useLocal from "../../hooks/useLocal";

export const Footer = memo(() => {
  const { t } = useLocal();
  return (
    <StyledFooter>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <span style={{ fontWeight: 900 }}>
          © 2020–2024, Rusarm Transits — {t("transportnie_passajiroperevozki")}
        </span>
      </div>
    </StyledFooter>
  );
});
Footer.displayName = "Footer";

const StyledFooter = styled.div`
  background: #f5f7fa;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  /* margin-top: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;
