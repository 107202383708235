export const transformPhoneNumber = (inputString: string, format: string) => {
  if (typeof inputString !== "string") {
    return "";
  }
  
  const digitsOnly = inputString.replace(/\D/g, "");

  let formattedString = "";
  let digitIndex = 0;

  for (let i = 0; i < format.length; i++) {
    const formatChar = format[i];
    if (formatChar === "0" && digitIndex < digitsOnly.length) {
      const x = format[i - 1];
      if (x === " " || x === "-") {
        formattedString += x + digitsOnly[digitIndex];
      } else {
        formattedString += digitsOnly[digitIndex];
      }
      digitIndex++;
    } else if (formatChar === "0") {
      break;
    }
  }

  return formattedString;
};
