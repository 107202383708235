import { memo, useCallback, useMemo, useState } from "react";
import verifyPhone from "../../../images/routes/bron.jpg";

import { useUnmount } from "react-use";
import { ConfirmationResult } from "firebase/auth";
import useLocal from "../../../hooks/useLocal";
import { formatDate, transformPhoneNumber } from "../../../utils";
import { StyledImg, StyledModalColumn, StyledModalRow } from "../styles";
import Button from "../../../components/Button";
import { OtpCode } from "../../../ui/OTPCode";
import { TelegramClient } from "messaging-api-telegram";
import { Step1Values } from "..";

interface VerificationProps {
  phone: string;
  onSuccess: () => void;
  onErrorSend?: () => void;
  step1Values: Step1Values;
  activeCountry:
    | {
        name: string;
        flag: string;
        placeholder: string;
        maxLength: number;
        phoneCode: string;
        shortPhoneCode: string;
      }
    | {
        name: string;
        flag: string;
        placeholder: string;
        maxLength: number;
        phoneCode: string;
        shortPhoneCode: string;
      };
}

type Confirmation = ConfirmationResult | null;

export const Verification = memo((props: VerificationProps) => {
  const { activeCountry, phone, onSuccess, onErrorSend, step1Values } = props;
  const [modalStep, setModalStep] = useState(1);
  const [confirmation, setConfirmation] = useState<Confirmation>(null);
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useUnmount(() => setModalStep(1));
  const { t } = useLocal();

  const client = new TelegramClient({
    accessToken: "7290784093:AAHh9bmVXbnD7oFbsF_4dOTSCeUmohJRMoA",
  });

  const sendCode = useCallback(async () => {
    try {
      const value = `${t(step1Values?.from || "")} - ${t(
        step1Values.to || ""
      )}, ${step1Values.tickets} билет, ${
        step1Values.date ? formatDate(String(step1Values.date)) : ""
      }, +${step1Values.phone}`;
      await client.sendMessage("-4285204537", value);
      onSuccess();
    } catch (error) {
      console.error(error);
    }
  }, [
    client,
    onSuccess,
    step1Values.date,
    step1Values?.from,
    step1Values.phone,
    step1Values.tickets,
    step1Values.to,
    t,
  ]);

  const verify = async () => {
    try {
      if (!confirmation) return;
      setIsLoading(true);
      const res = await confirmation.confirm(otp);
      if (res.user) onSuccess();
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const phoneView = useMemo(
    () =>
      `${activeCountry.phoneCode}${" "}${transformPhoneNumber(
        phone.replace(activeCountry.phoneCode.substring(1), ""),
        activeCountry.placeholder
      )}`,
    [activeCountry.phoneCode, activeCountry.placeholder, phone]
  );

  return (
    <StyledModalRow>
      <StyledImg src={verifyPhone} alt="verify" />
      {modalStep === 1 ? (
        <StyledModalColumn style={{ justifyContent: "space-evenly" }}>
          <span style={{ fontWeight: 500, fontSize: 18 }}>
            {t("modal_content_1")}
            <br style={{ marginTop: 10 }} />
            <br style={{ marginTop: 10 }} />
            {t("phone_number")}:{" "}
            <span style={{ fontWeight: 600 }}>{phoneView}</span>
          </span>
          <div id="recaptcha"></div>
          <StyledModalRow style={{ gap: "10px" }}>
            <Button
              theme="primary"
              customStyles={{
                background: "rgb(12, 115, 254)",
                minHeight: 40,
                width: "100%",
                marginTop: 20,
              }}
              content={
                <span style={{ fontWeight: 600 }}>{t("send_code")}</span>
              }
              onClick={sendCode}
              isLoading={isLoading}
            />
          </StyledModalRow>
        </StyledModalColumn>
      ) : (
        <StyledModalColumn>
          <span style={{ fontWeight: 500, fontSize: 22 }}>
            {t("code_sended_on")}: <br />
            <span style={{ fontWeight: 600, fontSize: 20 }}>{phoneView}</span>
            {/* <br /> */}
            <span
              style={{
                fontWeight: 600,
                fontSize: 16,
                display: "block",
                marginTop: 20,
                marginBottom: 5,
              }}
            >
              {t("type_6_code")}
            </span>
          </span>
          <OtpCode
            code={otp}
            inputsCount={6}
            onChange={(v) => {
              setOtp(v);
              setError(false);
            }}
            error={error}
          />
          <Button
            theme="primary"
            customStyles={{
              background:
                otp.length === 6 ? "rgb(12, 115, 254)" : "rgba(0, 0, 0, 0.3)",
              minHeight: 40,
              width: "100%",
              marginTop: 20,
              cursor: otp.length === 6 ? "pointer" : "default",
            }}
            content={
              <span style={{ fontWeight: 600 }}>{t("verify_code")}</span>
            }
            onClick={() => otp.length === 6 && verify()}
            isLoading={isLoading}
          />
        </StyledModalColumn>
      )}
    </StyledModalRow>
  );
});

Verification.displayName = "Verification";
