import { memo } from "react";
import { RouteComponent } from "../../components/RouteComponent";
import { useApp } from "../../contexts";
import { Helmet } from "react-helmet-async";
import useLocal from "../../hooks/useLocal";
import { useParams } from "react-router-dom";

export const RoutePage = memo(() => {
  const { isInitialized, findRoute } = useApp();
  const { t } = useLocal();
  const { name } = useParams<{ name: string }>();
  const route = findRoute(name || "");

  if (!isInitialized) return <></>;
  return (
    <>
      <Helmet>
        <title>{`${t(route.from)} - ${t(route.to)} ${t("bus")}`}</title>
        <meta name="description" content={"description"} />
        <meta property="og:title" content={"title"} />
        <meta
          property="og:description"
          content={`Комфортные пассажирские перевозки из ${t(route.from)} в ${t(
            route.to
          )} и обратно. Наши регулярные рейсы предлагают удобные маршруты и безопасные поездки. Забронируйте билеты прямо сейчас и наслаждайтесь комфортом в пути!`}
        />
        <meta
          name="keywords"
          content={`пассажирские перевозки ${t(route.from)} ${t(
            route.to
          )}, автобусы Армения Россия, удобные маршруты, безопасные поездки, бронирование билетов, комфортные автобусы, транспортные услуги, ուղևորափոխադրումներ ${t(
            route.from
          )} ${t(
            route.to
          )}, ավտոբուսներ Հայաստան Ռուսաստան, հարմար երթուղիներ, անվտանգ ուղևորություններ, տոմսերի ամրագրում, հարմարավետ ավտոբուսներ, տրանսպորտային ծառայություններ`}
        />
        {/* Add other meta tags as needed */}
      </Helmet>
      <RouteComponent />{" "}
    </>
  );
});
