import { memo } from "react";

import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
import useLocal from "../../hooks/useLocal";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface FormDatePickerProps {
  value: Date | null;
  onChange: (date: Value) => void;
}

export const FormDatePicker = memo((props: FormDatePickerProps) => {
  const { onChange, value } = props;
  const { t } = useLocal();

  return (
    <StyledDatePicker>
      <span>{t("select_date")}:</span>
      <DatePicker
        onChange={onChange}
        value={value}
        minDate={new Date()}
        clearIcon={null}
        calendarIcon={
          <span style={{ color: "rgba(6,86,254,1)", fontWeight: 600 }}>
            {t("open_calendar")}
          </span>
        }
        dayPlaceholder={t("day")}
        monthPlaceholder={t("month")}
        yearPlaceholder={t("year")}
      
      />
    </StyledDatePicker>
  );
});
FormDatePicker.displayName = "FormDatePicker";

const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;

  > span {
    font-size: 25px;
    font-weight: 600;
  }
  .react-date-picker__wrapper {
    height: 50px;
    background: white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 0 10px;
  }
  .react-date-picker__calendar {
    z-index: 21;
    inset: -288px auto auto -2px !important;
  }
  .react-date-picker__inputGroup {
    font-weight: 600;
    color: #0656fe;
    display: flex;
    align-items: center;
  }
  .react-calendar {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .react-date-picker__inputGroup__input {
    width: 45px;

    &::placeholder {
      color: rgba(6, 86, 254, 0.5);
    }
  }
  .react-date-picker__calendar-button {
    max-width: 100px;
  }
`;
