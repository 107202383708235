import { useLocation, useNavigate } from "react-router-dom";
import useLocal from "../../hooks/useLocal";
import { StyledH1, StyledH2, StyledHeader, StyledSalesImg } from "./styles";
import { useApp } from "../../contexts";
import sales from "../../images/salesBg.svg";
import { useWindowSize } from "react-use";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, language } = useLocal();
  const { setActiveReservation } = useApp();
  const { width } = useWindowSize();
  const transform = width > 568 ? "-5px" : "5px";

  return (
    <StyledHeader
      onClick={() => {
        if (location.pathname === "/reservation") return;
        setActiveReservation("");
        navigate("/reservation");
      }}
    >
      <StyledSalesImg src={sales} alt="sales" />
      <div
        className="header-content"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 2,
          // transform: 'rotate(-2deg)'
        }}
      >
        <StyledH1 $lang={language.value}>{t("lozung_head")}</StyledH1>
        <StyledH2 $lang={language.value}>{t("lozung")}</StyledH2>
      </div>
      <div
        style={{ userSelect: "none", transform: `translateY(${transform})` }}
        className="header-content header-content-price"
      >
        <span
          style={{
            color: "white",
            fontSize: 170,
            fontWeight: 900,
            display: "flex",
            alignItems: "center",
            position: "relative",
            cursor: "pointer",
          }}
        >
          <span
            style={{
              zIndex: 1,
              lineHeight: "90%",
              textShadow: "0 4px 4px rgba(0,0,0,0.35)",
              fontFamily: '"Montserrat Alternates", sans-serif',
            }}
          >
            7%
          </span>
          <span
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "15px",
              zIndex: 2,
              fontSize: 25,
              background: "#fa742d",
              padding: "3px 6px",
              borderRadius: 50,
              fontWeight: 600,
              rotate: "-2deg",
              width: "100%",
              minWidth: 320,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              boxShadow: "0 4px 3px rgba(0,0,0,0.25)",
            }}
          >
            {t("header_label_1")} <span style={{ fontWeight: 900 }}>7</span>{" "}
            {t("header_label_2")}
          </span>
        </span>
      </div>
    </StyledHeader>
  );
};

export default Header;
