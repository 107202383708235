import styled from "styled-components";
import { media } from "../../ui/config";

export const StyledRoutes = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin: 10px 0 0px;

  ${media.laptopS`
  grid-template-columns: repeat(2, 1fr);
  `}
  ${media.tablet`
  grid-template-columns: repeat(1, 1fr);
  `}
`;