import styled, { keyframes } from "styled-components";
import { media } from "../../ui/config";

export const StyledNavigation = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* height: 50px; */
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  background: radial-gradient(
    circle,
    rgba(6, 86, 254, 1) 15%,
    rgba(4, 72, 213, 1) 50%,
    rgb(0 72 221) 100%
  );
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  /* justify-content: space-between; */
  z-index: 100;

  .bron-header {
    background: none;
    border: 1px solid white;
    color: white;
    background: rgba(255, 255, 255, 0.02);
    transition: all 0.2s;
    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }
`;
export const StyledLogotype = styled.img`
  height: 45px;
  cursor: pointer;
  ${media.mobileL`
        display: none;
    `}
`;
export const StyledSmallLogotype = styled.img`
  height: 40px;
  cursor: pointer;
  display: none;
  ${media.mobileL`
        display: flex;
    `}
`;
export const StyledNavigationContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;
export const StyledNavigationButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-weight: 600;
  font-size: 14px;

  ${media.mobileL`
        >svg {
            width: 18px;
        }
        >svg:last-child {
            width: 15px;
        }
        font-size: 12px;
    `}
`;
export const StyledRightContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  button {
    height: 40px;
    border: none;
    outline: none;
    padding: 0 20px;
    border-radius: 150px;
    cursor: pointer;
    font-weight: 600;
    color: rgb(0 42 127);
  }

  ${media.mobileL`
    display: none;
  `}
`;
export const StyledMoreButtonSection = styled.div`
  display: none;
  ${media.tabletL`
        display: flex;
    `}
`;

export const StyledNavigationTop = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 4px 0;

  > div {
    height: 100%;
    > div {
      ${media.mobile`
        zoom: 0.9;
        `}
    }
  }

  .phone-item {
    text-decoration: none; /* Removes the underline */
    color: inherit; /* Inherits the color from its parent */
    font-weight: normal; /* Resets the font weight */
    font-size: inherit; /* Inherits the font size */
    background-color: transparent; /* Removes any background color */
    border: none; /* Removes any borders */
    outline: none;
    font-weight: 600;
    transition: all 0.2s;
    &:hover {
      color: rgb(250, 116, 45);
      cursor: pointer;
    }
  }

  ${media.mobileL`
  #insta-facebook {
    display: none !important;
  }
  `}
`;
export const StyledNavigationMore = styled.button`
  width: 30px;
  height: 30px;
  display: none;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: none;
  padding: 0;

  ${media.mobileL`
    display: flex;
  `}
`;
export const StyledNavigationMoreContent = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  left: 0;
  top: 86px;
  width: 100%;
  transition: all 0.3s;
  height: ${(p) => (p.$isOpen ? "100px" : 0)};
  background: red;
  overflow: hidden;
  background: radial-gradient(
    circle,
    rgb(6, 86, 254) 15%,
    rgb(4, 72, 213) 50%,
    rgb(0, 72, 221) 100%
  );
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.15);

  ${media.mobileL`
    display: flex;
  `}

  >:last-child {
    height: 35px;
    padding: 0 10px;
    border-radius: 999px;
  }
`;
