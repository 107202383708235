import styled from "styled-components";
import { media } from "../../ui/config";

export const StyledBronirovatOnline = styled.div`
  width: 100%;
  background: red;
  margin: 20px 0;
  background: linear-gradient(
    90deg,
    rgba(12, 115, 254, 1) 0%,
    rgba(6, 86, 254, 1) 100%
  );
  border-radius: 16px;
  padding: 20px 50px;
  position: relative;

  .hr {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.3);
    background: linear-gradient(
      90deg,
      transparent 0%,
      rgba(255, 255, 255, 0.2) 25%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 100%
    );
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.3); */
    margin-top: 20px;
    position: relative;
    span {
      color: red;
      position: absolute;
      top: -14px;
      background: white;
      color: rgba(6, 86, 254, 1);
      padding: 4px 10px;
      border-radius: 16px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 600;
    }
  }

  > img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 20px;
    top: 0;

    ${media.tabletL`
      display: none;
    `}
  }

  .web-block {
    ${media.mobileL`
      display: none;
    `}
  }

  .text-content-info {
    ${media.laptopS`
      zoom: 0.8;
    `}
    ${media.tabletL`
      zoom: 0.7;
    `}
    ${media.tabletL`
      zoom: 0.8;
      width: 100% !important;
      margin: unset !important;
    `}
  }
  ${media.mobileL`
    padding: 20px;
  `}
  button {
    ${media.mobileL`
    width: 100%;
    margin-top: 5px !important;
  `}
  }
`;